@mixin sfFutura($weight: 'Light', $style: '') {

	$fontStyle: normal;
	@if($style == 'Oblique') {
		$fontStyle: oblique;
	}

	$fontWeight: 100;
	@if($weight == 'Book') {
		$fontWeight: 200;
	}

    @if($weight == 'Medium') {
      $fontWeight: 400;
    }

	font-family: 'Futura PT Multilingual W02', Helvetica, Arial, sans-serif;
	font-weight: $fontWeight;
	font-style: $fontStyle;
}


@mixin keyFrameAnimation($animation, $duration, $iterationCount, $direction, $delay) {
	
	@include animation-name($animation);
	@include animation-duration($duration);
	@include animation-iteration-count($iterationCount);
	@include animation-direction($direction);
	@include animation-delay($delay);
}


@mixin keyframes($animationName) {
  @-webkit-keyframes #{$animationName} {
    @content;
  }
  @-moz-keyframes #{$animationName} {
    @content;
  }
  @-o-keyframes #{$animationName} {
    @content;
  }
  @keyframes #{$animationName} {
    @content;
  }
}


@mixin sfColor($colorType, $color, $colorHover: '', $colorActive: '', $colorFocus: '') {

	@if($colorActive == '') {
		$colorActive: ($colorHover);
	}

	@if($colorFocus == '') {
		$colorFocus: ($colorHover);
	}

	@if($colorType == 'color') {

		color: $color;

		@if($colorHover != '') {
			&.hover,
			&:hover {
				color: ($colorHover);
			}

			&.current,
			&.act,
			&.active,
			&:active {
				color: ($colorActive);
			}

			&.focus,
			&:focus {
				color: ($colorFocus);
			}
		}

	}
	@elseif ($colorType == 'border') {

		border-color: $color;

		@if($colorHover != '') {
			&.hover,
			&:hover {
				border-color: ($colorHover);
			}

			&.current,
			&.act,
			&.active,
			&:active {
				border-color: ($colorActive);
			}

			&.focus,
			&:focus {
				border-color: ($colorFocus);
			}
		}
	}
@else {

background-color:$color;

	@if($colorHover != '') {
		&.hover,
		&:hover {
			background-color: ($colorHover);
		}

		&.current,
		&.act,
		&.active,
		&:active {
			background-color: ($colorActive);
		}

		&.focus,
		&:focus {
			background-color: ($colorFocus);
		}
	}
}
}


@mixin sfBackgroundImage($type: 'svg', $image: 'sprite', $size: 1000px 1000px, $imagePath: $imagePathCSS) {
	@if($type == 'svg') {
		background-image: url(#{$imagePath}#{$image}.png);

		.svg & {
			background-image: url(#{$imagePath}#{$image}.svg);
		}

	} @else {
		background-image: url(#{$imagePath}#{$image}.png);
	}
	background-color: transparent;
	background-position: -9999px -9999px;
	background-repeat: no-repeat;
	@include background-size($size);
}


@mixin sfBackgroundPosition($pos, $posHover: '', $posActive: '', $posFocus: '') {
	@if($posActive == '') {
		$posActive: ($posHover);
	}

	@if($posFocus == '') {
		$posFocus: ($posHover);
	}

	background-position: $pos;

	@if($posHover != '') {
		&.hover,
		&:hover {
			background-position: ($posHover);
		}

		&.act,
		&.active,
		&:active {
			background-position: ($posActive);
		}

		&.focus,
		&:focus {
			background-position: ($posFocus);
		}
	}
}


@mixin sfRemoveTextByIndent() {
	text-indent: -9999px;
	white-space: nowrap;
	overflow: hidden;
}

@mixin sfShowTextByIndent() {
	text-indent: 0;
	white-space: normal;
	overflow: visible;
}


@mixin border-radius( $radius ) {

	-moz-border-radius: radius / radius;
  	-webkit-border-radius: radius radius;
  	border-radius: radius / radius;
}


@mixin single-box-shadow( $hoff: null, $voff: null, $blur: null, $spread: null, $color: null, $inset: null ) {

}



@mixin box-sizing( $box-model: null) {

}



@mixin animation-name( $animation ) {

}



@mixin animation-duration( $duration ) {


}
	
	
@mixin animation-iteration-count( $iterationCount ) {


}


@mixin animation-direction( $direction ) {


}


@mixin animation-delay( $delay ) {



}



@mixin text-shadow( $shadow ) {

}



@mixin background-size( $size... ) {


}




@mixin single-transition( $property, $duration, $function: null, $delay: null ) {
  
}



@mixin rotate( $rotate, $perspective: false, $only3d: false ) {


}




@mixin transform( $transform, $only3d: false ) {

}



@mixin transition-property($properties...) {


}




@mixin scale( $scale-x: 0, $scale-y: $scale-x, $perspective: false, $only3d: false ) {


}