$imagePathS1: "/fileadmin/images/microsites/s1/";
$imagePathCSS: "/fileadmin/images/microsites/s1/";

$colorBlack: #000000;
$colorWhite: #ffffff;
$colorLightGray: #b2b2b2;
$colorLightestGray: #ededed;
$colorGray: #808080;
$colorDarkGray: #333333;
$colorRed: #e1001f;
$colorYellow: #f1e7c7;
$colorLineGray: #d3d3d3;
$colorBackgroundDarkGray: #333333;
$colorBackgroundLightGray: #3d3d3d;
$colorBackgroundButton: #bbbbbb;
$colorGradientTop: #ededed;
$colorGradientBottom: $colorWhite;
$colorLightestGray: #f2efe9;
$colorLightGray: #ccc0a7;
$colorDarkGrayHover: #5e584d;
$colorDarkGray: $colorBlack;
$colorGray: $colorLightGray;
$colorBackgroundButton: $colorLightGray;
$colorBackgroundDarkGray: $colorDarkGray;
$colorBackgroundLightGray: $colorLightGray;
$colorLineGray: $colorLightGray;
$colorBGFacts: $colorLightestGray;
$colorGradientTop: $colorLightestGray;
$colorButtonLightBlue:#9ED4D2;
$colorButtonBorderLightBlue: #5DB7B3;


$CEBottom: 40px;
$gutter: 30px;
$contentWidth: 700px;




/* $mediaDesktopBig: "only screen and (min-width: 1280px)"; */

$mediaMin450: "only screen and (min-width: 450px), print";
$mediaMax450: "only screen and (max-width: 450px), print";
$mediaMin568: "only screen and (min-width: 568px), print";
$mediaMax568: "only screen and (max-width: 568px), print";
$mediaMin600: "only screen and (min-width: 600px), print";
$mediaMin750: "only screen and (min-width: 750px), print";
$mediaMax750: "only screen and (max-width: 750px), print";
$mediaMin768: "only screen and (min-width: 768px), print";
$mediaMin1024: "only screen and (min-width: 1024px), print";
$mediaMin1280: "only screen and (min-width: 1280px), print";


$mediaMin750MaxHeight640: "only screen and (min-width: 750px) and (max-height: 640px)";
$mediaMin1280MinHeight725: "only screen and (min-width: 1280px) and (min-height: 725px), print";
$mediaLandscapeMaxHeight768: "only screen and (orientation: landscape) and (max-height: 768px)";

$mediaIE10: "screen and (-ms-high-contrast: active), (-ms-high-contrast: none)";

/* 
@include keyframes(fadeOut) {
  0%   { opacity: 1; }
  50%   { opacity: 1; }
  100% { opacity: 0; }
}
*/