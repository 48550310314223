@charset "UTF-8";
/* $mediaDesktopBig: "only screen and (min-width: 1280px)"; */
/* 
@include keyframes(fadeOut) {
  0%   { opacity: 1; }
  50%   { opacity: 1; }
  100% { opacity: 0; }
}
*/
.contentS1Configurator {
  padding-bottom: 80px; }
  @media only screen and (min-width: 768px), print {
    .contentS1Configurator > .innerContent {
      max-width: 768px; } }
  .contentS1Configurator .configuratorIFrame {
    display: none; }
    @media only screen and (min-width: 768px), print {
      .contentS1Configurator .configuratorIFrame {
        display: block; } }
  @media only screen and (min-width: 768px), print {
    .contentS1Configurator .contentGallery,
    .contentS1Configurator p.bodytext {
      display: none; } }

/* SAUNAKONFIGURATOR NEU by schalk&friends */
#contentWrapper {
  /* Startseite */
  /* Steps 1 bis 6 */
  /* Konfiguration ansehen */ }
  #contentWrapper .contentFullScreenS1SaunaConfigurator,
  #contentWrapper .contentS1SaunaConfigurator {
    /*.innerContent {
      background: $colorLightestGray;
    }*/ }
    #contentWrapper .contentFullScreenS1SaunaConfigurator *:focus, #contentWrapper .contentFullScreenS1SaunaConfigurator *:active,
    #contentWrapper .contentS1SaunaConfigurator *:focus,
    #contentWrapper .contentS1SaunaConfigurator *:active {
      outline: none; }
    #contentWrapper .contentFullScreenS1SaunaConfigurator .hideMobile,
    #contentWrapper .contentS1SaunaConfigurator .hideMobile {
      display: none !important; }
      @media only screen and (min-width: 750px), print {
        #contentWrapper .contentFullScreenS1SaunaConfigurator .hideMobile,
        #contentWrapper .contentS1SaunaConfigurator .hideMobile {
          display: block !important; } }
    #contentWrapper .contentFullScreenS1SaunaConfigurator .showMobile,
    #contentWrapper .contentS1SaunaConfigurator .showMobile {
      display: block !important; }
      @media only screen and (min-width: 750px), print {
        #contentWrapper .contentFullScreenS1SaunaConfigurator .showMobile,
        #contentWrapper .contentS1SaunaConfigurator .showMobile {
          display: none !important; } }
    #contentWrapper .contentFullScreenS1SaunaConfigurator .hideMobileTable,
    #contentWrapper .contentS1SaunaConfigurator .hideMobileTable {
      display: none !important; }
      @media only screen and (min-width: 750px), print {
        #contentWrapper .contentFullScreenS1SaunaConfigurator .hideMobileTable,
        #contentWrapper .contentS1SaunaConfigurator .hideMobileTable {
          display: table !important; } }
    #contentWrapper .contentFullScreenS1SaunaConfigurator .showMobileTable,
    #contentWrapper .contentS1SaunaConfigurator .showMobileTable {
      display: table !important; }
      @media only screen and (min-width: 750px), print {
        #contentWrapper .contentFullScreenS1SaunaConfigurator .showMobileTable,
        #contentWrapper .contentS1SaunaConfigurator .showMobileTable {
          display: none !important; } }
    #contentWrapper .contentFullScreenS1SaunaConfigurator .steps,
    #contentWrapper .contentS1SaunaConfigurator .steps {
      font-size: 0.8888888889em;
      padding: 15px 0 0 0; }
      @media only screen and (min-width: 750px), print {
        #contentWrapper .contentFullScreenS1SaunaConfigurator .steps,
        #contentWrapper .contentS1SaunaConfigurator .steps {
          font-size: 1em;
          margin: 0 auto 30px;
          max-width: 700px;
          padding: 45px 0 0 0; } }
      .svg #contentWrapper .contentFullScreenS1SaunaConfigurator .steps li,
      #contentWrapper .contentFullScreenS1SaunaConfigurator .steps li, .svg
      #contentWrapper .contentS1SaunaConfigurator .steps li,
      #contentWrapper .contentS1SaunaConfigurator .steps li {
        background: none;
        color: #ffffff;
        display: block;
        padding-left: 0;
        text-shadow: 0 0 1px #000000, 0 0 80px #000000;
        width: 100%;
        font-family: 'Futura PT Multilingual W02', Helvetica, Arial, sans-serif;
        font-weight: 400;
        font-style: normal; }
        .lt-ie10 .svg #contentWrapper .contentFullScreenS1SaunaConfigurator .steps li, .lt-ie10
        #contentWrapper .contentFullScreenS1SaunaConfigurator .steps li, .lt-ie10 .svg
        #contentWrapper .contentS1SaunaConfigurator .steps li, .lt-ie10
        #contentWrapper .contentS1SaunaConfigurator .steps li {
          filter: progid:DXImageTransform.Microsoft.Glow(color=#666666, strength=1); }
        .safari .svg #contentWrapper .contentFullScreenS1SaunaConfigurator .steps li, .safari
        #contentWrapper .contentFullScreenS1SaunaConfigurator .steps li, .safari .svg
        #contentWrapper .contentS1SaunaConfigurator .steps li, .safari
        #contentWrapper .contentS1SaunaConfigurator .steps li {
          text-shadow: 0 0 1px #000000; }
        @media only screen and (min-width: 750px), print {
          .svg #contentWrapper .contentFullScreenS1SaunaConfigurator .steps li,
          #contentWrapper .contentFullScreenS1SaunaConfigurator .steps li, .svg
          #contentWrapper .contentS1SaunaConfigurator .steps li,
          #contentWrapper .contentS1SaunaConfigurator .steps li {
            float: left;
            width: 16.6666666667%; } }
        .svg #contentWrapper .contentFullScreenS1SaunaConfigurator .steps li span,
        #contentWrapper .contentFullScreenS1SaunaConfigurator .steps li span, .svg
        #contentWrapper .contentS1SaunaConfigurator .steps li span,
        #contentWrapper .contentS1SaunaConfigurator .steps li span {
          border: 2px solid #ffffff;
          display: block;
          height: 34px;
          line-height: 30px;
          margin: 0 auto 5px;
          position: relative;
          text-align: center;
          text-shadow: none;
          width: 34px;
          -moz-border-radius: radius / radius;
          -webkit-border-radius: radius radius;
          border-radius: radius / radius;
          background-color: #ffffff;
          color: #000000; }
          .lt-ie10 .svg #contentWrapper .contentFullScreenS1SaunaConfigurator .steps li span, .lt-ie10
          #contentWrapper .contentFullScreenS1SaunaConfigurator .steps li span, .lt-ie10 .svg
          #contentWrapper .contentS1SaunaConfigurator .steps li span, .lt-ie10
          #contentWrapper .contentS1SaunaConfigurator .steps li span {
            filter: none; }
          .safari .svg #contentWrapper .contentFullScreenS1SaunaConfigurator .steps li span, .safari
          #contentWrapper .contentFullScreenS1SaunaConfigurator .steps li span, .safari .svg
          #contentWrapper .contentS1SaunaConfigurator .steps li span, .safari
          #contentWrapper .contentS1SaunaConfigurator .steps li span {
            text-shadow: none; }
          @media only screen and (min-width: 750px), print {
            .svg #contentWrapper .contentFullScreenS1SaunaConfigurator .steps li span,
            #contentWrapper .contentFullScreenS1SaunaConfigurator .steps li span, .svg
            #contentWrapper .contentS1SaunaConfigurator .steps li span,
            #contentWrapper .contentS1SaunaConfigurator .steps li span {
              height: 44px;
              line-height: 40px;
              width: 44px; } }
          .svg #contentWrapper .contentFullScreenS1SaunaConfigurator .steps li span:before, .svg #contentWrapper .contentFullScreenS1SaunaConfigurator .steps li span:after,
          #contentWrapper .contentFullScreenS1SaunaConfigurator .steps li span:before,
          #contentWrapper .contentFullScreenS1SaunaConfigurator .steps li span:after, .svg
          #contentWrapper .contentS1SaunaConfigurator .steps li span:before, .svg
          #contentWrapper .contentS1SaunaConfigurator .steps li span:after,
          #contentWrapper .contentS1SaunaConfigurator .steps li span:before,
          #contentWrapper .contentS1SaunaConfigurator .steps li span:after {
            border-left: 2px solid #ffffff;
            content: "";
            display: block;
            left: 50%;
            margin-left: 0;
            position: absolute; }
          .svg #contentWrapper .contentFullScreenS1SaunaConfigurator .steps li span:before,
          #contentWrapper .contentFullScreenS1SaunaConfigurator .steps li span:before, .svg
          #contentWrapper .contentS1SaunaConfigurator .steps li span:before,
          #contentWrapper .contentS1SaunaConfigurator .steps li span:before {
            height: 12px;
            top: -12px; }
            @media only screen and (min-width: 750px), print {
              .svg #contentWrapper .contentFullScreenS1SaunaConfigurator .steps li span:before,
              #contentWrapper .contentFullScreenS1SaunaConfigurator .steps li span:before, .svg
              #contentWrapper .contentS1SaunaConfigurator .steps li span:before,
              #contentWrapper .contentS1SaunaConfigurator .steps li span:before {
                content: normal; } }
          .svg #contentWrapper .contentFullScreenS1SaunaConfigurator .steps li span:after,
          #contentWrapper .contentFullScreenS1SaunaConfigurator .steps li span:after, .svg
          #contentWrapper .contentS1SaunaConfigurator .steps li span:after,
          #contentWrapper .contentS1SaunaConfigurator .steps li span:after {
            bottom: -7px;
            height: 7px; }
            @media only screen and (min-width: 750px), print {
              .svg #contentWrapper .contentFullScreenS1SaunaConfigurator .steps li span:after,
              #contentWrapper .contentFullScreenS1SaunaConfigurator .steps li span:after, .svg
              #contentWrapper .contentS1SaunaConfigurator .steps li span:after,
              #contentWrapper .contentS1SaunaConfigurator .steps li span:after {
                border: none;
                border-top: 2px solid #ffffff;
                height: 0;
                left: 100%;
                margin: -1px 0 0 2px;
                top: 50%;
                width: 200%; } }
        .svg #contentWrapper .contentFullScreenS1SaunaConfigurator .steps li:first-child span:before,
        #contentWrapper .contentFullScreenS1SaunaConfigurator .steps li:first-child span:before, .svg
        #contentWrapper .contentS1SaunaConfigurator .steps li:first-child span:before,
        #contentWrapper .contentS1SaunaConfigurator .steps li:first-child span:before {
          content: normal; }
        @media only screen and (min-width: 750px), print {
          .svg #contentWrapper .contentFullScreenS1SaunaConfigurator .steps li:last-child span:after,
          #contentWrapper .contentFullScreenS1SaunaConfigurator .steps li:last-child span:after, .svg
          #contentWrapper .contentS1SaunaConfigurator .steps li:last-child span:after,
          #contentWrapper .contentS1SaunaConfigurator .steps li:last-child span:after {
            content: normal; } }
    #contentWrapper .contentFullScreenS1SaunaConfigurator .steps2,
    #contentWrapper .contentS1SaunaConfigurator .steps2 {
      font-size: 0.8888888889em;
      margin: 0;
      padding: 15px 0 0 0; }
      @media only screen and (min-width: 750px), print {
        #contentWrapper .contentFullScreenS1SaunaConfigurator .steps2,
        #contentWrapper .contentS1SaunaConfigurator .steps2 {
          font-size: 1em;
          margin: 0 auto 30px;
          max-width: 700px;
          padding: 90px 0 60px; } }
      .svg #contentWrapper .contentFullScreenS1SaunaConfigurator .steps2 li,
      #contentWrapper .contentFullScreenS1SaunaConfigurator .steps2 li, .svg
      #contentWrapper .contentS1SaunaConfigurator .steps2 li,
      #contentWrapper .contentS1SaunaConfigurator .steps2 li {
        background: none;
        color: #ffffff;
        display: block;
        margin-bottom: 30px;
        padding: 10px 0 0;
        position: relative;
        text-align: center;
        text-shadow: 0 0 1px #000000, 0 0 80px #000000;
        width: 100%;
        z-index: 1;
        font-family: 'Futura PT Multilingual W02', Helvetica, Arial, sans-serif;
        font-weight: 400;
        font-style: normal; }
        .lt-ie10 .svg #contentWrapper .contentFullScreenS1SaunaConfigurator .steps2 li, .lt-ie10
        #contentWrapper .contentFullScreenS1SaunaConfigurator .steps2 li, .lt-ie10 .svg
        #contentWrapper .contentS1SaunaConfigurator .steps2 li, .lt-ie10
        #contentWrapper .contentS1SaunaConfigurator .steps2 li {
          filter: progid:DXImageTransform.Microsoft.Glow(color=#666666, strength=1); }
        .safari .svg #contentWrapper .contentFullScreenS1SaunaConfigurator .steps2 li, .safari
        #contentWrapper .contentFullScreenS1SaunaConfigurator .steps2 li, .safari .svg
        #contentWrapper .contentS1SaunaConfigurator .steps2 li, .safari
        #contentWrapper .contentS1SaunaConfigurator .steps2 li {
          text-shadow: 0 0 1px #000000; }
        @media only screen and (min-width: 750px), print {
          .svg #contentWrapper .contentFullScreenS1SaunaConfigurator .steps2 li,
          #contentWrapper .contentFullScreenS1SaunaConfigurator .steps2 li, .svg
          #contentWrapper .contentS1SaunaConfigurator .steps2 li,
          #contentWrapper .contentS1SaunaConfigurator .steps2 li {
            border-top: 2px solid #ffffff;
            float: left;
            margin: 0;
            width: 16.6666666667%; } }
        .svg #contentWrapper .contentFullScreenS1SaunaConfigurator .steps2 li:before,
        #contentWrapper .contentFullScreenS1SaunaConfigurator .steps2 li:before, .svg
        #contentWrapper .contentS1SaunaConfigurator .steps2 li:before,
        #contentWrapper .contentS1SaunaConfigurator .steps2 li:before {
          display: block;
          height: 20px;
          width: 20px;
          position: absolute;
          top: -12px;
          left: 50%;
          margin-left: -10px;
          border: none;
          background: #ffffff;
          z-index: 0; }
          @media only screen and (min-width: 750px), print {
            .svg #contentWrapper .contentFullScreenS1SaunaConfigurator .steps2 li:before,
            #contentWrapper .contentFullScreenS1SaunaConfigurator .steps2 li:before, .svg
            #contentWrapper .contentS1SaunaConfigurator .steps2 li:before,
            #contentWrapper .contentS1SaunaConfigurator .steps2 li:before {
              content: "";
              width: auto;
              top: -6px;
              right: 2px;
              left: 2px;
              height: 10px;
              margin: 0; } }
        .svg #contentWrapper .contentFullScreenS1SaunaConfigurator .steps2 li:after,
        #contentWrapper .contentFullScreenS1SaunaConfigurator .steps2 li:after, .svg
        #contentWrapper .contentS1SaunaConfigurator .steps2 li:after,
        #contentWrapper .contentS1SaunaConfigurator .steps2 li:after {
          border-left: 2px solid #ffffff;
          content: "";
          display: block;
          height: 35px;
          left: 50%;
          margin-left: -1px;
          position: absolute;
          top: -25px; }
          @media only screen and (min-width: 750px), print {
            .svg #contentWrapper .contentFullScreenS1SaunaConfigurator .steps2 li:after,
            #contentWrapper .contentFullScreenS1SaunaConfigurator .steps2 li:after, .svg
            #contentWrapper .contentS1SaunaConfigurator .steps2 li:after,
            #contentWrapper .contentS1SaunaConfigurator .steps2 li:after {
              content: normal; } }
        .svg #contentWrapper .contentFullScreenS1SaunaConfigurator .steps2 li:first-child:after,
        #contentWrapper .contentFullScreenS1SaunaConfigurator .steps2 li:first-child:after, .svg
        #contentWrapper .contentS1SaunaConfigurator .steps2 li:first-child:after,
        #contentWrapper .contentS1SaunaConfigurator .steps2 li:first-child:after {
          content: normal; }
        @media only screen and (min-width: 750px), print {
          .svg #contentWrapper .contentFullScreenS1SaunaConfigurator .steps2 li:first-child:before,
          #contentWrapper .contentFullScreenS1SaunaConfigurator .steps2 li:first-child:before, .svg
          #contentWrapper .contentS1SaunaConfigurator .steps2 li:first-child:before,
          #contentWrapper .contentS1SaunaConfigurator .steps2 li:first-child:before {
            left: 0; }
          .svg #contentWrapper .contentFullScreenS1SaunaConfigurator .steps2 li:last-child:before,
          #contentWrapper .contentFullScreenS1SaunaConfigurator .steps2 li:last-child:before, .svg
          #contentWrapper .contentS1SaunaConfigurator .steps2 li:last-child:before,
          #contentWrapper .contentS1SaunaConfigurator .steps2 li:last-child:before {
            right: 0; } }
  #contentWrapper .contentFullScreenS1SaunaConfigurator img {
    width: 250%; }
    @media only screen and (min-width: 750px), print {
      #contentWrapper .contentFullScreenS1SaunaConfigurator img {
        width: 100%; } }
  #contentWrapper .contentFullScreenS1SaunaConfigurator .innerContent p.button {
    padding: 0;
    max-width: none;
    min-width: 0; }
    #contentWrapper .contentFullScreenS1SaunaConfigurator .innerContent p.button a {
      display: block;
      letter-spacing: 0.05em;
      line-height: 1.5555555556em;
      margin: 0;
      padding: 10px 50px;
      text-decoration: none;
      color: #000000;
      background-color: #ffffff; }
      #contentWrapper .contentFullScreenS1SaunaConfigurator .innerContent p.button a.hover, #contentWrapper .contentFullScreenS1SaunaConfigurator .innerContent p.button a:hover {
        color: #ffffff; }
      #contentWrapper .contentFullScreenS1SaunaConfigurator .innerContent p.button a.current, #contentWrapper .contentFullScreenS1SaunaConfigurator .innerContent p.button a.act, #contentWrapper .contentFullScreenS1SaunaConfigurator .innerContent p.button a.active, #contentWrapper .contentFullScreenS1SaunaConfigurator .innerContent p.button a:active {
        color: #ffffff; }
      #contentWrapper .contentFullScreenS1SaunaConfigurator .innerContent p.button a.focus, #contentWrapper .contentFullScreenS1SaunaConfigurator .innerContent p.button a:focus {
        color: #ffffff; }
      #contentWrapper .contentFullScreenS1SaunaConfigurator .innerContent p.button a.hover, #contentWrapper .contentFullScreenS1SaunaConfigurator .innerContent p.button a:hover {
        background-color: #5e584d; }
      #contentWrapper .contentFullScreenS1SaunaConfigurator .innerContent p.button a.current, #contentWrapper .contentFullScreenS1SaunaConfigurator .innerContent p.button a.act, #contentWrapper .contentFullScreenS1SaunaConfigurator .innerContent p.button a.active, #contentWrapper .contentFullScreenS1SaunaConfigurator .innerContent p.button a:active {
        background-color: #5e584d; }
      #contentWrapper .contentFullScreenS1SaunaConfigurator .innerContent p.button a.focus, #contentWrapper .contentFullScreenS1SaunaConfigurator .innerContent p.button a:focus {
        background-color: #5e584d; }
  #contentWrapper .contentS1SaunaConfigurator {
    padding-top: 0;
    /* Information-Layer */ }
    #contentWrapper .contentS1SaunaConfigurator > .innerContent {
      max-width: 700px;
      width: auto; }
      @media only screen and (min-width: 750px), print {
        #contentWrapper .contentS1SaunaConfigurator > .innerContent {
          margin: 0 auto 40px;
          max-width: 700px; } }
      @media only screen and (min-width: 1024px), print {
        #contentWrapper .contentS1SaunaConfigurator > .innerContent {
          max-width: 924px; } }
      @media only screen and (min-width: 1280px), print {
        #contentWrapper .contentS1SaunaConfigurator > .innerContent {
          max-width: 1180px; } }
    #contentWrapper .contentS1SaunaConfigurator h2 {
      font-family: 'Futura PT Multilingual W02', Helvetica, Arial, sans-serif;
      font-weight: 400;
      font-style: normal;
      margin-bottom: 15px; }
    #contentWrapper .contentS1SaunaConfigurator .stepsMobile {
      padding: 15px 0;
      position: relative; }
      #contentWrapper .contentS1SaunaConfigurator .stepsMobile li,
      .svg #contentWrapper .contentS1SaunaConfigurator .stepsMobile li {
        background-color: #ffffff;
        border: 2px solid #ccc0a7;
        color: #000000;
        font-size: 1.2em;
        height: 64px;
        line-height: 50px;
        margin: 0 auto;
        text-align: center;
        width: 64px;
        -moz-border-radius: radius / radius;
        -webkit-border-radius: radius radius;
        border-radius: radius / radius; }
        #contentWrapper .contentS1SaunaConfigurator .stepsMobile li.previous, #contentWrapper .contentS1SaunaConfigurator .stepsMobile li.next,
        .svg #contentWrapper .contentS1SaunaConfigurator .stepsMobile li.previous,
        .svg #contentWrapper .contentS1SaunaConfigurator .stepsMobile li.next {
          border: none;
          color: #ccc0a7;
          height: auto;
          position: absolute;
          top: 15px;
          width: auto;
          z-index: 1;
          -moz-border-radius: radius / radius;
          -webkit-border-radius: radius radius;
          border-radius: radius / radius; }
          #contentWrapper .contentS1SaunaConfigurator .stepsMobile li.previous:before, #contentWrapper .contentS1SaunaConfigurator .stepsMobile li.previous:after, #contentWrapper .contentS1SaunaConfigurator .stepsMobile li.next:before, #contentWrapper .contentS1SaunaConfigurator .stepsMobile li.next:after,
          .svg #contentWrapper .contentS1SaunaConfigurator .stepsMobile li.previous:before,
          .svg #contentWrapper .contentS1SaunaConfigurator .stepsMobile li.previous:after,
          .svg #contentWrapper .contentS1SaunaConfigurator .stepsMobile li.next:before,
          .svg #contentWrapper .contentS1SaunaConfigurator .stepsMobile li.next:after {
            display: inline-block;
            font-size: 1.5em;
            color: #ccc0a7; }
          #contentWrapper .contentS1SaunaConfigurator .stepsMobile li.previous a, #contentWrapper .contentS1SaunaConfigurator .stepsMobile li.next a,
          .svg #contentWrapper .contentS1SaunaConfigurator .stepsMobile li.previous a,
          .svg #contentWrapper .contentS1SaunaConfigurator .stepsMobile li.next a {
            color: #000000;
            text-decoration: none; }
          #contentWrapper .contentS1SaunaConfigurator .stepsMobile li.previous a:hover:before, #contentWrapper .contentS1SaunaConfigurator .stepsMobile li.previous a:hover:after,
          #contentWrapper .contentS1SaunaConfigurator .stepsMobile li.previous a:active:before,
          #contentWrapper .contentS1SaunaConfigurator .stepsMobile li.previous a:active:after,
          #contentWrapper .contentS1SaunaConfigurator .stepsMobile li.previous a:focus:before,
          #contentWrapper .contentS1SaunaConfigurator .stepsMobile li.previous a:focus:after, #contentWrapper .contentS1SaunaConfigurator .stepsMobile li.next a:hover:before, #contentWrapper .contentS1SaunaConfigurator .stepsMobile li.next a:hover:after,
          #contentWrapper .contentS1SaunaConfigurator .stepsMobile li.next a:active:before,
          #contentWrapper .contentS1SaunaConfigurator .stepsMobile li.next a:active:after,
          #contentWrapper .contentS1SaunaConfigurator .stepsMobile li.next a:focus:before,
          #contentWrapper .contentS1SaunaConfigurator .stepsMobile li.next a:focus:after,
          .svg #contentWrapper .contentS1SaunaConfigurator .stepsMobile li.previous a:hover:before,
          .svg #contentWrapper .contentS1SaunaConfigurator .stepsMobile li.previous a:hover:after,
          .svg #contentWrapper .contentS1SaunaConfigurator .stepsMobile li.previous a:active:before,
          .svg #contentWrapper .contentS1SaunaConfigurator .stepsMobile li.previous a:active:after,
          .svg #contentWrapper .contentS1SaunaConfigurator .stepsMobile li.previous a:focus:before,
          .svg #contentWrapper .contentS1SaunaConfigurator .stepsMobile li.previous a:focus:after,
          .svg #contentWrapper .contentS1SaunaConfigurator .stepsMobile li.next a:hover:before,
          .svg #contentWrapper .contentS1SaunaConfigurator .stepsMobile li.next a:hover:after,
          .svg #contentWrapper .contentS1SaunaConfigurator .stepsMobile li.next a:active:before,
          .svg #contentWrapper .contentS1SaunaConfigurator .stepsMobile li.next a:active:after,
          .svg #contentWrapper .contentS1SaunaConfigurator .stepsMobile li.next a:focus:before,
          .svg #contentWrapper .contentS1SaunaConfigurator .stepsMobile li.next a:focus:after {
            color: #000000; }
        #contentWrapper .contentS1SaunaConfigurator .stepsMobile li.previous,
        .svg #contentWrapper .contentS1SaunaConfigurator .stepsMobile li.previous {
          left: 0;
          text-align: left; }
          #contentWrapper .contentS1SaunaConfigurator .stepsMobile li.previous:before,
          .svg #contentWrapper .contentS1SaunaConfigurator .stepsMobile li.previous:before {
            content: "‹";
            margin-right: 5px; }
        #contentWrapper .contentS1SaunaConfigurator .stepsMobile li.next,
        .svg #contentWrapper .contentS1SaunaConfigurator .stepsMobile li.next {
          right: 0;
          text-align: right; }
          #contentWrapper .contentS1SaunaConfigurator .stepsMobile li.next:after,
          .svg #contentWrapper .contentS1SaunaConfigurator .stepsMobile li.next:after {
            content: "›";
            margin-left: 5px; }
    .svg #contentWrapper .contentS1SaunaConfigurator .stepsContent li,
    #contentWrapper .contentS1SaunaConfigurator .stepsContent li {
      color: #000000;
      text-align: center;
      text-shadow: none; }
      .lt-ie10 .svg #contentWrapper .contentS1SaunaConfigurator .stepsContent li, .lt-ie10
      #contentWrapper .contentS1SaunaConfigurator .stepsContent li {
        filter: none; }
      .safari .svg #contentWrapper .contentS1SaunaConfigurator .stepsContent li, .safari
      #contentWrapper .contentS1SaunaConfigurator .stepsContent li {
        text-shadow: none; }
      .svg #contentWrapper .contentS1SaunaConfigurator .stepsContent li span,
      #contentWrapper .contentS1SaunaConfigurator .stepsContent li span {
        background: #ffffff;
        border-color: #ccc0a7;
        color: #000000; }
        .svg #contentWrapper .contentS1SaunaConfigurator .stepsContent li span:before, .svg #contentWrapper .contentS1SaunaConfigurator .stepsContent li span:after,
        #contentWrapper .contentS1SaunaConfigurator .stepsContent li span:before,
        #contentWrapper .contentS1SaunaConfigurator .stepsContent li span:after {
          border-color: #ccc0a7; }
      .svg #contentWrapper .contentS1SaunaConfigurator .stepsContent li.active span,
      #contentWrapper .contentS1SaunaConfigurator .stepsContent li.active span {
        background: #5e584d;
        border-color: #5e584d;
        color: #ffffff; }
      .svg #contentWrapper .contentS1SaunaConfigurator .stepsContent li a,
      #contentWrapper .contentS1SaunaConfigurator .stepsContent li a {
        text-decoration: none;
        color: #ccc0a7; }
        .svg #contentWrapper .contentS1SaunaConfigurator .stepsContent li a span,
        #contentWrapper .contentS1SaunaConfigurator .stepsContent li a span {
          color: #ffffff;
          background: #ccc0a7; }
    #contentWrapper .contentS1SaunaConfigurator .stepsContent2 {
      padding: 45px 0 0 0; }
      .svg #contentWrapper .contentS1SaunaConfigurator .stepsContent2 li,
      #contentWrapper .contentS1SaunaConfigurator .stepsContent2 li {
        border-top-color: #ccc0a7;
        color: #000000;
        text-shadow: none; }
        .lt-ie10 .svg #contentWrapper .contentS1SaunaConfigurator .stepsContent2 li, .lt-ie10
        #contentWrapper .contentS1SaunaConfigurator .stepsContent2 li {
          filter: none; }
        .safari .svg #contentWrapper .contentS1SaunaConfigurator .stepsContent2 li, .safari
        #contentWrapper .contentS1SaunaConfigurator .stepsContent2 li {
          text-shadow: none; }
        .svg #contentWrapper .contentS1SaunaConfigurator .stepsContent2 li:before,
        #contentWrapper .contentS1SaunaConfigurator .stepsContent2 li:before {
          background: none; }
        .svg #contentWrapper .contentS1SaunaConfigurator .stepsContent2 li.active:before,
        #contentWrapper .contentS1SaunaConfigurator .stepsContent2 li.active:before {
          background: #ccc0a7; }
        .svg #contentWrapper .contentS1SaunaConfigurator .stepsContent2 li a,
        #contentWrapper .contentS1SaunaConfigurator .stepsContent2 li a {
          text-decoration: none; }
          .svg #contentWrapper .contentS1SaunaConfigurator .stepsContent2 li a:before,
          #contentWrapper .contentS1SaunaConfigurator .stepsContent2 li a:before {
            background: #ccc0a7;
            border: none;
            content: "";
            display: block;
            height: 10px;
            left: 2px;
            position: absolute;
            right: 2px;
            top: -6px;
            z-index: 0; }
        .svg #contentWrapper .contentS1SaunaConfigurator .stepsContent2 li:first-child a:before,
        #contentWrapper .contentS1SaunaConfigurator .stepsContent2 li:first-child a:before {
          left: 0; }
        .svg #contentWrapper .contentS1SaunaConfigurator .stepsContent2 li:last-child a:before,
        #contentWrapper .contentS1SaunaConfigurator .stepsContent2 li:last-child a:before {
          right: 0; }
    #contentWrapper .contentS1SaunaConfigurator .tools {
      padding: 0 0 15px 0;
      position: relative; }
      #contentWrapper .contentS1SaunaConfigurator .tools li,
      .svg #contentWrapper .contentS1SaunaConfigurator .tools li {
        background: none;
        display: block;
        margin-bottom: 15px;
        padding: 0;
        text-align: left;
        font-family: 'Futura PT Multilingual W02', Helvetica, Arial, sans-serif;
        font-weight: 400;
        font-style: normal; }
        @media only screen and (min-width: 750px), print {
          #contentWrapper .contentS1SaunaConfigurator .tools li,
          .svg #contentWrapper .contentS1SaunaConfigurator .tools li {
            float: left;
            width: 50%; } }
        #contentWrapper .contentS1SaunaConfigurator .tools li.back:before, #contentWrapper .contentS1SaunaConfigurator .tools li.restart:before,
        .svg #contentWrapper .contentS1SaunaConfigurator .tools li.back:before,
        .svg #contentWrapper .contentS1SaunaConfigurator .tools li.restart:before {
          display: inline-block;
          font-size: 1.5em;
          margin-right: 5px;
          color: #ccc0a7; }
        #contentWrapper .contentS1SaunaConfigurator .tools li.back a, #contentWrapper .contentS1SaunaConfigurator .tools li.restart a,
        .svg #contentWrapper .contentS1SaunaConfigurator .tools li.back a,
        .svg #contentWrapper .contentS1SaunaConfigurator .tools li.restart a {
          text-decoration: none;
          color: #000000; }
          #contentWrapper .contentS1SaunaConfigurator .tools li.back a.hover, #contentWrapper .contentS1SaunaConfigurator .tools li.back a:hover, #contentWrapper .contentS1SaunaConfigurator .tools li.restart a.hover, #contentWrapper .contentS1SaunaConfigurator .tools li.restart a:hover,
          .svg #contentWrapper .contentS1SaunaConfigurator .tools li.back a.hover,
          .svg #contentWrapper .contentS1SaunaConfigurator .tools li.back a:hover,
          .svg #contentWrapper .contentS1SaunaConfigurator .tools li.restart a.hover,
          .svg #contentWrapper .contentS1SaunaConfigurator .tools li.restart a:hover {
            color: #ccc0a7; }
          #contentWrapper .contentS1SaunaConfigurator .tools li.back a.current, #contentWrapper .contentS1SaunaConfigurator .tools li.back a.act, #contentWrapper .contentS1SaunaConfigurator .tools li.back a.active, #contentWrapper .contentS1SaunaConfigurator .tools li.back a:active, #contentWrapper .contentS1SaunaConfigurator .tools li.restart a.current, #contentWrapper .contentS1SaunaConfigurator .tools li.restart a.act, #contentWrapper .contentS1SaunaConfigurator .tools li.restart a.active, #contentWrapper .contentS1SaunaConfigurator .tools li.restart a:active,
          .svg #contentWrapper .contentS1SaunaConfigurator .tools li.back a.current,
          .svg #contentWrapper .contentS1SaunaConfigurator .tools li.back a.act,
          .svg #contentWrapper .contentS1SaunaConfigurator .tools li.back a.active,
          .svg #contentWrapper .contentS1SaunaConfigurator .tools li.back a:active,
          .svg #contentWrapper .contentS1SaunaConfigurator .tools li.restart a.current,
          .svg #contentWrapper .contentS1SaunaConfigurator .tools li.restart a.act,
          .svg #contentWrapper .contentS1SaunaConfigurator .tools li.restart a.active,
          .svg #contentWrapper .contentS1SaunaConfigurator .tools li.restart a:active {
            color: #ccc0a7; }
          #contentWrapper .contentS1SaunaConfigurator .tools li.back a.focus, #contentWrapper .contentS1SaunaConfigurator .tools li.back a:focus, #contentWrapper .contentS1SaunaConfigurator .tools li.restart a.focus, #contentWrapper .contentS1SaunaConfigurator .tools li.restart a:focus,
          .svg #contentWrapper .contentS1SaunaConfigurator .tools li.back a.focus,
          .svg #contentWrapper .contentS1SaunaConfigurator .tools li.back a:focus,
          .svg #contentWrapper .contentS1SaunaConfigurator .tools li.restart a.focus,
          .svg #contentWrapper .contentS1SaunaConfigurator .tools li.restart a:focus {
            color: #ccc0a7; }
        #contentWrapper .contentS1SaunaConfigurator .tools li.back a:hover:before,
        #contentWrapper .contentS1SaunaConfigurator .tools li.back a:active:before,
        #contentWrapper .contentS1SaunaConfigurator .tools li.back a:focus:before, #contentWrapper .contentS1SaunaConfigurator .tools li.restart a:hover:before,
        #contentWrapper .contentS1SaunaConfigurator .tools li.restart a:active:before,
        #contentWrapper .contentS1SaunaConfigurator .tools li.restart a:focus:before,
        .svg #contentWrapper .contentS1SaunaConfigurator .tools li.back a:hover:before,
        .svg #contentWrapper .contentS1SaunaConfigurator .tools li.back a:active:before,
        .svg #contentWrapper .contentS1SaunaConfigurator .tools li.back a:focus:before,
        .svg #contentWrapper .contentS1SaunaConfigurator .tools li.restart a:hover:before,
        .svg #contentWrapper .contentS1SaunaConfigurator .tools li.restart a:active:before,
        .svg #contentWrapper .contentS1SaunaConfigurator .tools li.restart a:focus:before {
          color: #000000; }
        #contentWrapper .contentS1SaunaConfigurator .tools li.back:before,
        .svg #contentWrapper .contentS1SaunaConfigurator .tools li.back:before {
          content: "‹"; }
        @media only screen and (min-width: 750px), print {
          #contentWrapper .contentS1SaunaConfigurator .tools li.restart,
          .svg #contentWrapper .contentS1SaunaConfigurator .tools li.restart {
            text-align: right; } }
        #contentWrapper .contentS1SaunaConfigurator .tools li.restart:before,
        .svg #contentWrapper .contentS1SaunaConfigurator .tools li.restart:before {
          content: "";
          height: 20px;
          margin-right: 5px;
          position: relative;
          top: 4px;
          width: 20px;
          background-image: url(/fileadmin/images/microsites/s1/icons-s1-konfigurator.png);
          background-color: transparent;
          background-position: -9999px -9999px;
          background-repeat: no-repeat;
          background-position: -450px -100px; }
          .svg #contentWrapper .contentS1SaunaConfigurator .tools li.restart:before, .svg
          .svg #contentWrapper .contentS1SaunaConfigurator .tools li.restart:before {
            background-image: url(/fileadmin/images/microsites/s1/icons-s1-konfigurator.svg); }
    #contentWrapper .contentS1SaunaConfigurator .previewImage {
      margin: 0 auto 15px;
      max-width: 575px;
      position: relative; }
      @media only screen and (min-width: 1024px), print {
        #contentWrapper .contentS1SaunaConfigurator .previewImage {
          max-width: 445px; } }
      @media only screen and (min-width: 1280px), print {
        #contentWrapper .contentS1SaunaConfigurator .previewImage {
          max-width: 575px; } }
      #contentWrapper .contentS1SaunaConfigurator .previewImage .showConfiguration {
        display: block;
        margin: 0 auto;
        height: 0;
        overflow: hidden;
        position: relative;
        width: 100%;
        padding-top: 80%; }
        @media only screen and (min-width: 750px), print {
          #contentWrapper .contentS1SaunaConfigurator .previewImage .showConfiguration {
            height: 350px;
            padding: 0;
            width: 450px; } }
        @media only screen and (min-width: 1024px), print {
          #contentWrapper .contentS1SaunaConfigurator .previewImage .showConfiguration {
            height: 0;
            padding-top: 75%;
            width: 100%; } }
        @media only screen and (min-width: 1280px), print {
          #contentWrapper .contentS1SaunaConfigurator .previewImage .showConfiguration {
            width: 450px;
            margin: 0 auto;
            padding-top: 68.5%; } }
        #contentWrapper .contentS1SaunaConfigurator .previewImage .showConfiguration img {
          display: block;
          left: 50%;
          margin: -50% 0 0 -50%;
          position: absolute;
          top: 50%;
          width: 100%; }
        #contentWrapper .contentS1SaunaConfigurator .previewImage .showConfiguration:after {
          bottom: 0;
          content: "";
          display: block;
          height: 30px;
          opacity: 1;
          position: absolute;
          right: 0;
          width: 30px;
          background-image: url(/fileadmin/images/microsites/s1/icons-s1-konfigurator.png);
          background-color: transparent;
          background-position: -9999px -9999px;
          background-repeat: no-repeat;
          background-position: -450px 0; }
          .svg #contentWrapper .contentS1SaunaConfigurator .previewImage .showConfiguration:after {
            background-image: url(/fileadmin/images/microsites/s1/icons-s1-konfigurator.svg); }
        #contentWrapper .contentS1SaunaConfigurator .previewImage .showConfiguration p {
          color: #ffffff;
          display: block;
          font-size: 0.8888888889em;
          left: 50%;
          line-height: 1.5em;
          margin: -45px 0 0 -150px;
          opacity: 0;
          padding-top: 66px;
          position: absolute;
          text-align: center;
          text-decoration: none;
          text-shadow: 0 0 1px #000000, 0 0 80px #000000;
          top: 50%;
          width: 300px;
          z-index: 2;
          font-family: 'Futura PT Multilingual W02', Helvetica, Arial, sans-serif;
          font-weight: 400;
          font-style: normal; }
          #contentWrapper .contentS1SaunaConfigurator .previewImage .showConfiguration p:before {
            content: "";
            display: block;
            height: 60px;
            left: 50%;
            margin-left: -30px;
            position: absolute;
            top: 0;
            width: 60px;
            background-image: url(/fileadmin/images/microsites/s1/icons-s1-konfigurator.png);
            background-color: transparent;
            background-position: -9999px -9999px;
            background-repeat: no-repeat;
            background-position: -200px 0; }
            .svg #contentWrapper .contentS1SaunaConfigurator .previewImage .showConfiguration p:before {
              background-image: url(/fileadmin/images/microsites/s1/icons-s1-konfigurator.svg); }
        #contentWrapper .contentS1SaunaConfigurator .previewImage .showConfiguration:hover p, #contentWrapper .contentS1SaunaConfigurator .previewImage .showConfiguration:focus p, #contentWrapper .contentS1SaunaConfigurator .previewImage .showConfiguration:active p {
          opacity: 1; }
        #contentWrapper .contentS1SaunaConfigurator .previewImage .showConfiguration:hover:after, #contentWrapper .contentS1SaunaConfigurator .previewImage .showConfiguration:focus:after, #contentWrapper .contentS1SaunaConfigurator .previewImage .showConfiguration:active:after {
          opacity: 0; }
      #contentWrapper .contentS1SaunaConfigurator .previewImage div.showConfiguration:before, #contentWrapper .contentS1SaunaConfigurator .previewImage div.showConfiguration:after {
        content: normal; }
      #contentWrapper .contentS1SaunaConfigurator .previewImage .more {
        margin-bottom: 30px;
        padding: 0;
        text-decoration: none;
        font-family: 'Futura PT Multilingual W02', Helvetica, Arial, sans-serif;
        font-weight: 400;
        font-style: normal; }
        #contentWrapper .contentS1SaunaConfigurator .previewImage .more:before {
          content: normal; }
        #contentWrapper .contentS1SaunaConfigurator .previewImage .more:after {
          color: #ccc0a7;
          content: "›";
          display: inline-block;
          font-size: 1.5em;
          margin-left: 5px; }
      #contentWrapper .contentS1SaunaConfigurator .previewImage .switch {
        background: #ffffff;
        border: 2px solid #ccc0a7;
        height: 40px;
        left: -13px;
        margin: 0 0 0 0px;
        padding: 0;
        position: absolute;
        top: 309px;
        width: 76px;
        -moz-border-radius: radius / radius;
        -webkit-border-radius: radius radius;
        border-radius: radius / radius; }
        @media only screen and (min-width: 1024px), print {
          #contentWrapper .contentS1SaunaConfigurator .previewImage .switch {
            left: -91px;
            top: 350px; } }
        @media only screen and (min-width: 1280px), print {
          #contentWrapper .contentS1SaunaConfigurator .previewImage .switch {
            left: -25px;
            top: 355px; } }
        #contentWrapper .contentS1SaunaConfigurator .previewImage .switch li,
        .svg #contentWrapper .contentS1SaunaConfigurator .previewImage .switch li {
          background: none;
          border: 5px solid #ffffff;
          cursor: pointer;
          display: block;
          float: left;
          height: 36px;
          padding: 0;
          position: relative;
          width: 36px;
          -moz-border-radius: radius / radius;
          -webkit-border-radius: radius radius;
          border-radius: radius / radius; }
          #contentWrapper .contentS1SaunaConfigurator .previewImage .switch li.active,
          .svg #contentWrapper .contentS1SaunaConfigurator .previewImage .switch li.active {
            background: #ccc0a7; }
            #contentWrapper .contentS1SaunaConfigurator .previewImage .switch li.active:hover, #contentWrapper .contentS1SaunaConfigurator .previewImage .switch li.active:active, #contentWrapper .contentS1SaunaConfigurator .previewImage .switch li.active:focus,
            .svg #contentWrapper .contentS1SaunaConfigurator .previewImage .switch li.active:hover,
            .svg #contentWrapper .contentS1SaunaConfigurator .previewImage .switch li.active:active,
            .svg #contentWrapper .contentS1SaunaConfigurator .previewImage .switch li.active:focus {
              background: #5e584d; }
          #contentWrapper .contentS1SaunaConfigurator .previewImage .switch li span,
          .svg #contentWrapper .contentS1SaunaConfigurator .previewImage .switch li span {
            display: block;
            font-size: 0.6666666667em;
            left: 50%;
            letter-spacing: 0.05em;
            margin: 0 0 0 -70px;
            position: absolute;
            text-align: left;
            text-transform: uppercase;
            top: 40px;
            width: 140px;
            font-family: 'Futura PT Multilingual W02', Helvetica, Arial, sans-serif;
            font-weight: 400;
            font-style: normal; }
          #contentWrapper .contentS1SaunaConfigurator .previewImage .switch li:last-child span,
          .svg #contentWrapper .contentS1SaunaConfigurator .previewImage .switch li:last-child span {
            text-align: right; }
    #contentWrapper .contentS1SaunaConfigurator .configurationWrapper .previewImage .switch {
      top: 311px; }
      @media only screen and (min-width: 1024px), print {
        #contentWrapper .contentS1SaunaConfigurator .configurationWrapper .previewImage .switch {
          left: 48px;
          top: 294px; } }
      @media only screen and (min-width: 1280px), print {
        #contentWrapper .contentS1SaunaConfigurator .configurationWrapper .previewImage .switch {
          left: 49px;
          top: 355px; } }
    #contentWrapper .contentS1SaunaConfigurator .previewConfiguration table {
      border-bottom: 2px solid #000000;
      border-top: 2px solid #000000;
      font-size: 0.8888888889em;
      line-height: 1.5em;
      margin-bottom: 60px;
      width: 100%; }
      #contentWrapper .contentS1SaunaConfigurator .previewConfiguration table th,
      #contentWrapper .contentS1SaunaConfigurator .previewConfiguration table td {
        border: none;
        border-bottom: 1px solid #ccc0a7;
        padding: 5px 5px 5px 10px; }
      #contentWrapper .contentS1SaunaConfigurator .previewConfiguration table .big th,
      #contentWrapper .contentS1SaunaConfigurator .previewConfiguration table .big td {
        background: #f2efe9; }
      #contentWrapper .contentS1SaunaConfigurator .previewConfiguration table .big th {
        letter-spacing: 0.05em;
        text-transform: uppercase;
        width: 50%;
        font-family: 'Futura PT Multilingual W02', Helvetica, Arial, sans-serif;
        font-weight: 400;
        font-style: normal; }
    #contentWrapper .contentS1SaunaConfigurator .previewConfiguration h2,
    #contentWrapper .contentS1SaunaConfigurator .previewConfiguration h3,
    #contentWrapper .contentS1SaunaConfigurator .previewConfiguration p {
      margin-bottom: 0;
      text-align: center; }
    #contentWrapper .contentS1SaunaConfigurator .previewConfiguration h3 {
      margin-bottom: 5px;
      font-family: 'Futura PT Multilingual W02', Helvetica, Arial, sans-serif;
      font-weight: 400;
      font-style: normal; }
    #contentWrapper .contentS1SaunaConfigurator .previewConfiguration h2 {
      font-size: 2em;
      margin-bottom: 5px; }
    #contentWrapper .contentS1SaunaConfigurator .previewConfiguration .more {
      padding: 0;
      text-decoration: none;
      color: #000000;
      font-family: 'Futura PT Multilingual W02', Helvetica, Arial, sans-serif;
      font-weight: 400;
      font-style: normal; }
      #contentWrapper .contentS1SaunaConfigurator .previewConfiguration .more.hover, #contentWrapper .contentS1SaunaConfigurator .previewConfiguration .more:hover {
        color: #000000; }
      #contentWrapper .contentS1SaunaConfigurator .previewConfiguration .more.current, #contentWrapper .contentS1SaunaConfigurator .previewConfiguration .more.act, #contentWrapper .contentS1SaunaConfigurator .previewConfiguration .more.active, #contentWrapper .contentS1SaunaConfigurator .previewConfiguration .more:active {
        color: #000000; }
      #contentWrapper .contentS1SaunaConfigurator .previewConfiguration .more.focus, #contentWrapper .contentS1SaunaConfigurator .previewConfiguration .more:focus {
        color: #000000; }
      #contentWrapper .contentS1SaunaConfigurator .previewConfiguration .more:before {
        content: normal; }
      #contentWrapper .contentS1SaunaConfigurator .previewConfiguration .more:after {
        color: #ccc0a7;
        content: "›";
        display: inline-block;
        font-size: 1.5em;
        margin-left: 5px; }
      #contentWrapper .contentS1SaunaConfigurator .previewConfiguration .more:hover, #contentWrapper .contentS1SaunaConfigurator .previewConfiguration .more:focus, #contentWrapper .contentS1SaunaConfigurator .previewConfiguration .more:active {
        text-decoration: underline; }
    #contentWrapper .contentS1SaunaConfigurator .buttonWrapper {
      margin: 0 0 50px; }
      @media only screen and (min-width: 750px), print {
        #contentWrapper .contentS1SaunaConfigurator .buttonWrapper {
          margin: 0 0 50px; } }
      @media only screen and (min-width: 1024px), print {
        #contentWrapper .contentS1SaunaConfigurator .buttonWrapper {
          margin: 0 auto 50px; } }
      #contentWrapper .contentS1SaunaConfigurator .buttonWrapper p {
        margin-bottom: 10px; }
      #contentWrapper .contentS1SaunaConfigurator .buttonWrapper a.button {
        border: 2px solid #ccc0a7;
        display: block;
        letter-spacing: 0.05em;
        line-height: 1.5555555556em;
        padding: 10px 15px;
        text-decoration: none;
        text-transform: uppercase;
        background-color: #ffffff;
        border-color: #ccc0a7;
        color: #000000;
        font-family: 'Futura PT Multilingual W02', Helvetica, Arial, sans-serif;
        font-weight: 400;
        font-style: normal; }
        #contentWrapper .contentS1SaunaConfigurator .buttonWrapper a.button.hover, #contentWrapper .contentS1SaunaConfigurator .buttonWrapper a.button:hover {
          background-color: #5e584d; }
        #contentWrapper .contentS1SaunaConfigurator .buttonWrapper a.button.current, #contentWrapper .contentS1SaunaConfigurator .buttonWrapper a.button.act, #contentWrapper .contentS1SaunaConfigurator .buttonWrapper a.button.active, #contentWrapper .contentS1SaunaConfigurator .buttonWrapper a.button:active {
          background-color: #5e584d; }
        #contentWrapper .contentS1SaunaConfigurator .buttonWrapper a.button.focus, #contentWrapper .contentS1SaunaConfigurator .buttonWrapper a.button:focus {
          background-color: #5e584d; }
        #contentWrapper .contentS1SaunaConfigurator .buttonWrapper a.button.hover, #contentWrapper .contentS1SaunaConfigurator .buttonWrapper a.button:hover {
          border-color: #5e584d; }
        #contentWrapper .contentS1SaunaConfigurator .buttonWrapper a.button.current, #contentWrapper .contentS1SaunaConfigurator .buttonWrapper a.button.act, #contentWrapper .contentS1SaunaConfigurator .buttonWrapper a.button.active, #contentWrapper .contentS1SaunaConfigurator .buttonWrapper a.button:active {
          border-color: #5e584d; }
        #contentWrapper .contentS1SaunaConfigurator .buttonWrapper a.button.focus, #contentWrapper .contentS1SaunaConfigurator .buttonWrapper a.button:focus {
          border-color: #5e584d; }
        #contentWrapper .contentS1SaunaConfigurator .buttonWrapper a.button.hover, #contentWrapper .contentS1SaunaConfigurator .buttonWrapper a.button:hover {
          color: #ffffff; }
        #contentWrapper .contentS1SaunaConfigurator .buttonWrapper a.button.current, #contentWrapper .contentS1SaunaConfigurator .buttonWrapper a.button.act, #contentWrapper .contentS1SaunaConfigurator .buttonWrapper a.button.active, #contentWrapper .contentS1SaunaConfigurator .buttonWrapper a.button:active {
          color: #ffffff; }
        #contentWrapper .contentS1SaunaConfigurator .buttonWrapper a.button.focus, #contentWrapper .contentS1SaunaConfigurator .buttonWrapper a.button:focus {
          color: #ffffff; }
        @media only screen and (min-width: 750px), print {
          #contentWrapper .contentS1SaunaConfigurator .buttonWrapper a.button {
            max-width: 400px; } }
      #contentWrapper .contentS1SaunaConfigurator .buttonWrapper a.buttonDark {
        background-color: #f2efe9; }
        #contentWrapper .contentS1SaunaConfigurator .buttonWrapper a.buttonDark.hover, #contentWrapper .contentS1SaunaConfigurator .buttonWrapper a.buttonDark:hover {
          background-color: #5e584d; }
        #contentWrapper .contentS1SaunaConfigurator .buttonWrapper a.buttonDark.current, #contentWrapper .contentS1SaunaConfigurator .buttonWrapper a.buttonDark.act, #contentWrapper .contentS1SaunaConfigurator .buttonWrapper a.buttonDark.active, #contentWrapper .contentS1SaunaConfigurator .buttonWrapper a.buttonDark:active {
          background-color: #5e584d; }
        #contentWrapper .contentS1SaunaConfigurator .buttonWrapper a.buttonDark.focus, #contentWrapper .contentS1SaunaConfigurator .buttonWrapper a.buttonDark:focus {
          background-color: #5e584d; }
    #contentWrapper .contentS1SaunaConfigurator .tabWrapper {
      margin-bottom: 25px;
      position: relative; }
      #contentWrapper .contentS1SaunaConfigurator .tabWrapper .tabs {
        display: none; }
        @media only screen and (min-width: 750px), print {
          #contentWrapper .contentS1SaunaConfigurator .tabWrapper .tabs {
            display: block;
            left: 0;
            position: static;
            text-align: left;
            top: 0; } }
        @media only screen and (min-width: 750px), print {
          #contentWrapper .contentS1SaunaConfigurator .tabWrapper .tabs li {
            border: none;
            display: block;
            float: left;
            margin: 0 30px 0 0;
            padding: 0;
            position: relative;
            z-index: 2; } }
        #contentWrapper .contentS1SaunaConfigurator .tabWrapper .tabs li span,
        #contentWrapper .contentS1SaunaConfigurator .tabWrapper .tabs li a {
          text-transform: none;
          display: block; }
          @media only screen and (min-width: 750px), print {
            #contentWrapper .contentS1SaunaConfigurator .tabWrapper .tabs li span,
            #contentWrapper .contentS1SaunaConfigurator .tabWrapper .tabs li a {
              border: 2px solid #ccc0a7;
              padding: 10px 15px;
              position: relative;
              color: #000000; }
              #contentWrapper .contentS1SaunaConfigurator .tabWrapper .tabs li span.hover, #contentWrapper .contentS1SaunaConfigurator .tabWrapper .tabs li span:hover,
              #contentWrapper .contentS1SaunaConfigurator .tabWrapper .tabs li a.hover,
              #contentWrapper .contentS1SaunaConfigurator .tabWrapper .tabs li a:hover {
                color: #ccc0a7; }
              #contentWrapper .contentS1SaunaConfigurator .tabWrapper .tabs li span.current, #contentWrapper .contentS1SaunaConfigurator .tabWrapper .tabs li span.act, #contentWrapper .contentS1SaunaConfigurator .tabWrapper .tabs li span.active, #contentWrapper .contentS1SaunaConfigurator .tabWrapper .tabs li span:active,
              #contentWrapper .contentS1SaunaConfigurator .tabWrapper .tabs li a.current,
              #contentWrapper .contentS1SaunaConfigurator .tabWrapper .tabs li a.act,
              #contentWrapper .contentS1SaunaConfigurator .tabWrapper .tabs li a.active,
              #contentWrapper .contentS1SaunaConfigurator .tabWrapper .tabs li a:active {
                color: #ccc0a7; }
              #contentWrapper .contentS1SaunaConfigurator .tabWrapper .tabs li span.focus, #contentWrapper .contentS1SaunaConfigurator .tabWrapper .tabs li span:focus,
              #contentWrapper .contentS1SaunaConfigurator .tabWrapper .tabs li a.focus,
              #contentWrapper .contentS1SaunaConfigurator .tabWrapper .tabs li a:focus {
                color: #ccc0a7; }
              #contentWrapper .contentS1SaunaConfigurator .tabWrapper .tabs li span:after,
              #contentWrapper .contentS1SaunaConfigurator .tabWrapper .tabs li a:after {
                color: #ccc0a7;
                content: "›";
                display: block;
                font-size: 2em;
                height: 30px;
                line-height: 23px;
                position: absolute;
                right: -32px;
                text-align: center;
                top: 7px;
                width: 30px; } }
        @media only screen and (min-width: 750px), print {
          #contentWrapper .contentS1SaunaConfigurator .tabWrapper .tabs li:last-child {
            margin-right: 0; }
            #contentWrapper .contentS1SaunaConfigurator .tabWrapper .tabs li:last-child span:after,
            #contentWrapper .contentS1SaunaConfigurator .tabWrapper .tabs li:last-child a:after {
              content: normal; } }
        @media only screen and (min-width: 750px), print {
          #contentWrapper .contentS1SaunaConfigurator .tabWrapper .tabs li.ui-state-active a {
            background: #f2efe9;
            border-bottom: 0;
            margin-bottom: -2px;
            padding-bottom: 22px;
            color: #000000; }
            #contentWrapper .contentS1SaunaConfigurator .tabWrapper .tabs li.ui-state-active a.hover, #contentWrapper .contentS1SaunaConfigurator .tabWrapper .tabs li.ui-state-active a:hover {
              color: #000000; }
            #contentWrapper .contentS1SaunaConfigurator .tabWrapper .tabs li.ui-state-active a.current, #contentWrapper .contentS1SaunaConfigurator .tabWrapper .tabs li.ui-state-active a.act, #contentWrapper .contentS1SaunaConfigurator .tabWrapper .tabs li.ui-state-active a.active, #contentWrapper .contentS1SaunaConfigurator .tabWrapper .tabs li.ui-state-active a:active {
              color: #000000; }
            #contentWrapper .contentS1SaunaConfigurator .tabWrapper .tabs li.ui-state-active a.focus, #contentWrapper .contentS1SaunaConfigurator .tabWrapper .tabs li.ui-state-active a:focus {
              color: #000000; } }
        #contentWrapper .contentS1SaunaConfigurator .tabWrapper .tabs li.inactive span {
          color: #ccc0a7; }
      #contentWrapper .contentS1SaunaConfigurator .tabWrapper .button {
        line-height: 1.3333333333em;
        margin: 30px 0 0 0; }
        @media only screen and (min-width: 1024px), print {
          #contentWrapper .contentS1SaunaConfigurator .tabWrapper .button {
            margin: 0;
            position: absolute;
            right: 0;
            top: 0; } }
      #contentWrapper .contentS1SaunaConfigurator .tabWrapper > .button a,
      #contentWrapper .contentS1SaunaConfigurator .tabWrapper > h3 {
        border: 2px solid #ccc0a7;
        cursor: pointer;
        display: block;
        margin-bottom: 5px;
        padding: 10px 50px 10px 15px;
        position: relative;
        text-decoration: none;
        text-transform: none;
        background-color: #ffffff;
        color: #000000;
        font-family: 'Futura PT Multilingual W02', Helvetica, Arial, sans-serif;
        font-weight: 400;
        font-style: normal; }
        #contentWrapper .contentS1SaunaConfigurator .tabWrapper > .button a.hover, #contentWrapper .contentS1SaunaConfigurator .tabWrapper > .button a:hover,
        #contentWrapper .contentS1SaunaConfigurator .tabWrapper > h3.hover,
        #contentWrapper .contentS1SaunaConfigurator .tabWrapper > h3:hover {
          background-color: #ccc0a7; }
        #contentWrapper .contentS1SaunaConfigurator .tabWrapper > .button a.current, #contentWrapper .contentS1SaunaConfigurator .tabWrapper > .button a.act, #contentWrapper .contentS1SaunaConfigurator .tabWrapper > .button a.active, #contentWrapper .contentS1SaunaConfigurator .tabWrapper > .button a:active,
        #contentWrapper .contentS1SaunaConfigurator .tabWrapper > h3.current,
        #contentWrapper .contentS1SaunaConfigurator .tabWrapper > h3.act,
        #contentWrapper .contentS1SaunaConfigurator .tabWrapper > h3.active,
        #contentWrapper .contentS1SaunaConfigurator .tabWrapper > h3:active {
          background-color: #ccc0a7; }
        #contentWrapper .contentS1SaunaConfigurator .tabWrapper > .button a.focus, #contentWrapper .contentS1SaunaConfigurator .tabWrapper > .button a:focus,
        #contentWrapper .contentS1SaunaConfigurator .tabWrapper > h3.focus,
        #contentWrapper .contentS1SaunaConfigurator .tabWrapper > h3:focus {
          background-color: #ccc0a7; }
        #contentWrapper .contentS1SaunaConfigurator .tabWrapper > .button a.hover, #contentWrapper .contentS1SaunaConfigurator .tabWrapper > .button a:hover,
        #contentWrapper .contentS1SaunaConfigurator .tabWrapper > h3.hover,
        #contentWrapper .contentS1SaunaConfigurator .tabWrapper > h3:hover {
          color: #ffffff; }
        #contentWrapper .contentS1SaunaConfigurator .tabWrapper > .button a.current, #contentWrapper .contentS1SaunaConfigurator .tabWrapper > .button a.act, #contentWrapper .contentS1SaunaConfigurator .tabWrapper > .button a.active, #contentWrapper .contentS1SaunaConfigurator .tabWrapper > .button a:active,
        #contentWrapper .contentS1SaunaConfigurator .tabWrapper > h3.current,
        #contentWrapper .contentS1SaunaConfigurator .tabWrapper > h3.act,
        #contentWrapper .contentS1SaunaConfigurator .tabWrapper > h3.active,
        #contentWrapper .contentS1SaunaConfigurator .tabWrapper > h3:active {
          color: #ffffff; }
        #contentWrapper .contentS1SaunaConfigurator .tabWrapper > .button a.focus, #contentWrapper .contentS1SaunaConfigurator .tabWrapper > .button a:focus,
        #contentWrapper .contentS1SaunaConfigurator .tabWrapper > h3.focus,
        #contentWrapper .contentS1SaunaConfigurator .tabWrapper > h3:focus {
          color: #ffffff; }
        #contentWrapper .contentS1SaunaConfigurator .tabWrapper > .button a:after,
        #contentWrapper .contentS1SaunaConfigurator .tabWrapper > h3:after {
          content: "›";
          display: block;
          font-size: 2em;
          height: 30px;
          line-height: 23px;
          margin-top: -15px;
          position: absolute;
          right: 8px;
          text-align: center;
          top: 50%;
          width: 30px; }
        #contentWrapper .contentS1SaunaConfigurator .tabWrapper > .button a.inactive,
        #contentWrapper .contentS1SaunaConfigurator .tabWrapper > h3.inactive {
          background-color: #ffffff;
          color: #ccc0a7;
          cursor: default; }
          #contentWrapper .contentS1SaunaConfigurator .tabWrapper > .button a.inactive.hover, #contentWrapper .contentS1SaunaConfigurator .tabWrapper > .button a.inactive:hover,
          #contentWrapper .contentS1SaunaConfigurator .tabWrapper > h3.inactive.hover,
          #contentWrapper .contentS1SaunaConfigurator .tabWrapper > h3.inactive:hover {
            background-color: #ffffff; }
          #contentWrapper .contentS1SaunaConfigurator .tabWrapper > .button a.inactive.current, #contentWrapper .contentS1SaunaConfigurator .tabWrapper > .button a.inactive.act, #contentWrapper .contentS1SaunaConfigurator .tabWrapper > .button a.inactive.active, #contentWrapper .contentS1SaunaConfigurator .tabWrapper > .button a.inactive:active,
          #contentWrapper .contentS1SaunaConfigurator .tabWrapper > h3.inactive.current,
          #contentWrapper .contentS1SaunaConfigurator .tabWrapper > h3.inactive.act,
          #contentWrapper .contentS1SaunaConfigurator .tabWrapper > h3.inactive.active,
          #contentWrapper .contentS1SaunaConfigurator .tabWrapper > h3.inactive:active {
            background-color: #ffffff; }
          #contentWrapper .contentS1SaunaConfigurator .tabWrapper > .button a.inactive.focus, #contentWrapper .contentS1SaunaConfigurator .tabWrapper > .button a.inactive:focus,
          #contentWrapper .contentS1SaunaConfigurator .tabWrapper > h3.inactive.focus,
          #contentWrapper .contentS1SaunaConfigurator .tabWrapper > h3.inactive:focus {
            background-color: #ffffff; }
          #contentWrapper .contentS1SaunaConfigurator .tabWrapper > .button a.inactive.hover, #contentWrapper .contentS1SaunaConfigurator .tabWrapper > .button a.inactive:hover,
          #contentWrapper .contentS1SaunaConfigurator .tabWrapper > h3.inactive.hover,
          #contentWrapper .contentS1SaunaConfigurator .tabWrapper > h3.inactive:hover {
            color: #ccc0a7; }
          #contentWrapper .contentS1SaunaConfigurator .tabWrapper > .button a.inactive.current, #contentWrapper .contentS1SaunaConfigurator .tabWrapper > .button a.inactive.act, #contentWrapper .contentS1SaunaConfigurator .tabWrapper > .button a.inactive.active, #contentWrapper .contentS1SaunaConfigurator .tabWrapper > .button a.inactive:active,
          #contentWrapper .contentS1SaunaConfigurator .tabWrapper > h3.inactive.current,
          #contentWrapper .contentS1SaunaConfigurator .tabWrapper > h3.inactive.act,
          #contentWrapper .contentS1SaunaConfigurator .tabWrapper > h3.inactive.active,
          #contentWrapper .contentS1SaunaConfigurator .tabWrapper > h3.inactive:active {
            color: #ccc0a7; }
          #contentWrapper .contentS1SaunaConfigurator .tabWrapper > .button a.inactive.focus, #contentWrapper .contentS1SaunaConfigurator .tabWrapper > .button a.inactive:focus,
          #contentWrapper .contentS1SaunaConfigurator .tabWrapper > h3.inactive.focus,
          #contentWrapper .contentS1SaunaConfigurator .tabWrapper > h3.inactive:focus {
            color: #ccc0a7; }
          #contentWrapper .contentS1SaunaConfigurator .tabWrapper > .button a.inactive:after,
          #contentWrapper .contentS1SaunaConfigurator .tabWrapper > h3.inactive:after {
            content: normal; }
      #contentWrapper .contentS1SaunaConfigurator .tabWrapper > .buttonDark a {
        background-color: #f2efe9; }
        #contentWrapper .contentS1SaunaConfigurator .tabWrapper > .buttonDark a.hover, #contentWrapper .contentS1SaunaConfigurator .tabWrapper > .buttonDark a:hover {
          background-color: #ccc0a7; }
        #contentWrapper .contentS1SaunaConfigurator .tabWrapper > .buttonDark a.current, #contentWrapper .contentS1SaunaConfigurator .tabWrapper > .buttonDark a.act, #contentWrapper .contentS1SaunaConfigurator .tabWrapper > .buttonDark a.active, #contentWrapper .contentS1SaunaConfigurator .tabWrapper > .buttonDark a:active {
          background-color: #ccc0a7; }
        #contentWrapper .contentS1SaunaConfigurator .tabWrapper > .buttonDark a.focus, #contentWrapper .contentS1SaunaConfigurator .tabWrapper > .buttonDark a:focus {
          background-color: #ccc0a7; }
        @media only screen and (min-width: 750px), print {
          #contentWrapper .contentS1SaunaConfigurator .tabWrapper > .buttonDark a {
            background-color: #ffffff; }
            #contentWrapper .contentS1SaunaConfigurator .tabWrapper > .buttonDark a.hover, #contentWrapper .contentS1SaunaConfigurator .tabWrapper > .buttonDark a:hover {
              background-color: #ccc0a7; }
            #contentWrapper .contentS1SaunaConfigurator .tabWrapper > .buttonDark a.current, #contentWrapper .contentS1SaunaConfigurator .tabWrapper > .buttonDark a.act, #contentWrapper .contentS1SaunaConfigurator .tabWrapper > .buttonDark a.active, #contentWrapper .contentS1SaunaConfigurator .tabWrapper > .buttonDark a:active {
              background-color: #ccc0a7; }
            #contentWrapper .contentS1SaunaConfigurator .tabWrapper > .buttonDark a.focus, #contentWrapper .contentS1SaunaConfigurator .tabWrapper > .buttonDark a:focus {
              background-color: #ccc0a7; } }
      @media only screen and (min-width: 750px), print {
        #contentWrapper .contentS1SaunaConfigurator .tabWrapper > h3 {
          display: none; } }
      #contentWrapper .contentS1SaunaConfigurator .tabWrapper .tabElement {
        background: #f2efe9;
        margin-bottom: 5px;
        padding: 15px 15px 5px;
        z-index: auto; }
        @media only screen and (min-width: 750px), print {
          #contentWrapper .contentS1SaunaConfigurator .tabWrapper .tabElement {
            border: 2px solid #ccc0a7;
            position: relative; } }
      #contentWrapper .contentS1SaunaConfigurator .tabWrapper .selectionButtons,
      #contentWrapper .contentS1SaunaConfigurator .tabWrapper .multiSelectionButtons {
        margin: 0; }
        #contentWrapper .contentS1SaunaConfigurator .tabWrapper .selectionButtons li,
        .svg #contentWrapper .contentS1SaunaConfigurator .tabWrapper .selectionButtons li,
        #contentWrapper .contentS1SaunaConfigurator .tabWrapper .multiSelectionButtons li,
        .svg
        #contentWrapper .contentS1SaunaConfigurator .tabWrapper .multiSelectionButtons li {
          background: none;
          margin-bottom: 10px;
          padding: 0; }
          @media only screen and (min-width: 750px), print {
            #contentWrapper .contentS1SaunaConfigurator .tabWrapper .selectionButtons li,
            .svg #contentWrapper .contentS1SaunaConfigurator .tabWrapper .selectionButtons li,
            #contentWrapper .contentS1SaunaConfigurator .tabWrapper .multiSelectionButtons li,
            .svg
            #contentWrapper .contentS1SaunaConfigurator .tabWrapper .multiSelectionButtons li {
              display: block;
              float: left;
              margin: 0 10px 10px 0; } }
          #contentWrapper .contentS1SaunaConfigurator .tabWrapper .selectionButtons li a,
          .svg #contentWrapper .contentS1SaunaConfigurator .tabWrapper .selectionButtons li a,
          #contentWrapper .contentS1SaunaConfigurator .tabWrapper .multiSelectionButtons li a,
          .svg
          #contentWrapper .contentS1SaunaConfigurator .tabWrapper .multiSelectionButtons li a {
            border: 2px solid #ccc0a7;
            display: block;
            padding: 10px 15px;
            text-align: center;
            text-decoration: none;
            background-color: #ffffff;
            color: #000000;
            font-family: 'Futura PT Multilingual W02', Helvetica, Arial, sans-serif;
            font-weight: 400;
            font-style: normal; }
            #contentWrapper .contentS1SaunaConfigurator .tabWrapper .selectionButtons li a.hover, #contentWrapper .contentS1SaunaConfigurator .tabWrapper .selectionButtons li a:hover,
            .svg #contentWrapper .contentS1SaunaConfigurator .tabWrapper .selectionButtons li a.hover,
            .svg #contentWrapper .contentS1SaunaConfigurator .tabWrapper .selectionButtons li a:hover,
            #contentWrapper .contentS1SaunaConfigurator .tabWrapper .multiSelectionButtons li a.hover,
            #contentWrapper .contentS1SaunaConfigurator .tabWrapper .multiSelectionButtons li a:hover,
            .svg
            #contentWrapper .contentS1SaunaConfigurator .tabWrapper .multiSelectionButtons li a.hover,
            .svg
            #contentWrapper .contentS1SaunaConfigurator .tabWrapper .multiSelectionButtons li a:hover {
              background-color: #ccc0a7; }
            #contentWrapper .contentS1SaunaConfigurator .tabWrapper .selectionButtons li a.current, #contentWrapper .contentS1SaunaConfigurator .tabWrapper .selectionButtons li a.act, #contentWrapper .contentS1SaunaConfigurator .tabWrapper .selectionButtons li a.active, #contentWrapper .contentS1SaunaConfigurator .tabWrapper .selectionButtons li a:active,
            .svg #contentWrapper .contentS1SaunaConfigurator .tabWrapper .selectionButtons li a.current,
            .svg #contentWrapper .contentS1SaunaConfigurator .tabWrapper .selectionButtons li a.act,
            .svg #contentWrapper .contentS1SaunaConfigurator .tabWrapper .selectionButtons li a.active,
            .svg #contentWrapper .contentS1SaunaConfigurator .tabWrapper .selectionButtons li a:active,
            #contentWrapper .contentS1SaunaConfigurator .tabWrapper .multiSelectionButtons li a.current,
            #contentWrapper .contentS1SaunaConfigurator .tabWrapper .multiSelectionButtons li a.act,
            #contentWrapper .contentS1SaunaConfigurator .tabWrapper .multiSelectionButtons li a.active,
            #contentWrapper .contentS1SaunaConfigurator .tabWrapper .multiSelectionButtons li a:active,
            .svg
            #contentWrapper .contentS1SaunaConfigurator .tabWrapper .multiSelectionButtons li a.current,
            .svg
            #contentWrapper .contentS1SaunaConfigurator .tabWrapper .multiSelectionButtons li a.act,
            .svg
            #contentWrapper .contentS1SaunaConfigurator .tabWrapper .multiSelectionButtons li a.active,
            .svg
            #contentWrapper .contentS1SaunaConfigurator .tabWrapper .multiSelectionButtons li a:active {
              background-color: #ccc0a7; }
            #contentWrapper .contentS1SaunaConfigurator .tabWrapper .selectionButtons li a.focus, #contentWrapper .contentS1SaunaConfigurator .tabWrapper .selectionButtons li a:focus,
            .svg #contentWrapper .contentS1SaunaConfigurator .tabWrapper .selectionButtons li a.focus,
            .svg #contentWrapper .contentS1SaunaConfigurator .tabWrapper .selectionButtons li a:focus,
            #contentWrapper .contentS1SaunaConfigurator .tabWrapper .multiSelectionButtons li a.focus,
            #contentWrapper .contentS1SaunaConfigurator .tabWrapper .multiSelectionButtons li a:focus,
            .svg
            #contentWrapper .contentS1SaunaConfigurator .tabWrapper .multiSelectionButtons li a.focus,
            .svg
            #contentWrapper .contentS1SaunaConfigurator .tabWrapper .multiSelectionButtons li a:focus {
              background-color: #ccc0a7; }
            #contentWrapper .contentS1SaunaConfigurator .tabWrapper .selectionButtons li a.hover, #contentWrapper .contentS1SaunaConfigurator .tabWrapper .selectionButtons li a:hover,
            .svg #contentWrapper .contentS1SaunaConfigurator .tabWrapper .selectionButtons li a.hover,
            .svg #contentWrapper .contentS1SaunaConfigurator .tabWrapper .selectionButtons li a:hover,
            #contentWrapper .contentS1SaunaConfigurator .tabWrapper .multiSelectionButtons li a.hover,
            #contentWrapper .contentS1SaunaConfigurator .tabWrapper .multiSelectionButtons li a:hover,
            .svg
            #contentWrapper .contentS1SaunaConfigurator .tabWrapper .multiSelectionButtons li a.hover,
            .svg
            #contentWrapper .contentS1SaunaConfigurator .tabWrapper .multiSelectionButtons li a:hover {
              color: #ffffff; }
            #contentWrapper .contentS1SaunaConfigurator .tabWrapper .selectionButtons li a.current, #contentWrapper .contentS1SaunaConfigurator .tabWrapper .selectionButtons li a.act, #contentWrapper .contentS1SaunaConfigurator .tabWrapper .selectionButtons li a.active, #contentWrapper .contentS1SaunaConfigurator .tabWrapper .selectionButtons li a:active,
            .svg #contentWrapper .contentS1SaunaConfigurator .tabWrapper .selectionButtons li a.current,
            .svg #contentWrapper .contentS1SaunaConfigurator .tabWrapper .selectionButtons li a.act,
            .svg #contentWrapper .contentS1SaunaConfigurator .tabWrapper .selectionButtons li a.active,
            .svg #contentWrapper .contentS1SaunaConfigurator .tabWrapper .selectionButtons li a:active,
            #contentWrapper .contentS1SaunaConfigurator .tabWrapper .multiSelectionButtons li a.current,
            #contentWrapper .contentS1SaunaConfigurator .tabWrapper .multiSelectionButtons li a.act,
            #contentWrapper .contentS1SaunaConfigurator .tabWrapper .multiSelectionButtons li a.active,
            #contentWrapper .contentS1SaunaConfigurator .tabWrapper .multiSelectionButtons li a:active,
            .svg
            #contentWrapper .contentS1SaunaConfigurator .tabWrapper .multiSelectionButtons li a.current,
            .svg
            #contentWrapper .contentS1SaunaConfigurator .tabWrapper .multiSelectionButtons li a.act,
            .svg
            #contentWrapper .contentS1SaunaConfigurator .tabWrapper .multiSelectionButtons li a.active,
            .svg
            #contentWrapper .contentS1SaunaConfigurator .tabWrapper .multiSelectionButtons li a:active {
              color: #ffffff; }
            #contentWrapper .contentS1SaunaConfigurator .tabWrapper .selectionButtons li a.focus, #contentWrapper .contentS1SaunaConfigurator .tabWrapper .selectionButtons li a:focus,
            .svg #contentWrapper .contentS1SaunaConfigurator .tabWrapper .selectionButtons li a.focus,
            .svg #contentWrapper .contentS1SaunaConfigurator .tabWrapper .selectionButtons li a:focus,
            #contentWrapper .contentS1SaunaConfigurator .tabWrapper .multiSelectionButtons li a.focus,
            #contentWrapper .contentS1SaunaConfigurator .tabWrapper .multiSelectionButtons li a:focus,
            .svg
            #contentWrapper .contentS1SaunaConfigurator .tabWrapper .multiSelectionButtons li a.focus,
            .svg
            #contentWrapper .contentS1SaunaConfigurator .tabWrapper .multiSelectionButtons li a:focus {
              color: #ffffff; }
          #contentWrapper .contentS1SaunaConfigurator .tabWrapper .selectionButtons li.info,
          .svg #contentWrapper .contentS1SaunaConfigurator .tabWrapper .selectionButtons li.info,
          #contentWrapper .contentS1SaunaConfigurator .tabWrapper .multiSelectionButtons li.info,
          .svg
          #contentWrapper .contentS1SaunaConfigurator .tabWrapper .multiSelectionButtons li.info {
            text-align: right; }
            @media only screen and (min-width: 750px), print {
              #contentWrapper .contentS1SaunaConfigurator .tabWrapper .selectionButtons li.info,
              .svg #contentWrapper .contentS1SaunaConfigurator .tabWrapper .selectionButtons li.info,
              #contentWrapper .contentS1SaunaConfigurator .tabWrapper .multiSelectionButtons li.info,
              .svg
              #contentWrapper .contentS1SaunaConfigurator .tabWrapper .multiSelectionButtons li.info {
                text-align: left;
                margin: 0 10px 20px 5px; } }
            #contentWrapper .contentS1SaunaConfigurator .tabWrapper .selectionButtons li.info a,
            .svg #contentWrapper .contentS1SaunaConfigurator .tabWrapper .selectionButtons li.info a,
            #contentWrapper .contentS1SaunaConfigurator .tabWrapper .multiSelectionButtons li.info a,
            .svg
            #contentWrapper .contentS1SaunaConfigurator .tabWrapper .multiSelectionButtons li.info a {
              padding: 0; }
            #contentWrapper .contentS1SaunaConfigurator .tabWrapper .selectionButtons li.info .openLayer,
            .svg #contentWrapper .contentS1SaunaConfigurator .tabWrapper .selectionButtons li.info .openLayer,
            #contentWrapper .contentS1SaunaConfigurator .tabWrapper .multiSelectionButtons li.info .openLayer,
            .svg
            #contentWrapper .contentS1SaunaConfigurator .tabWrapper .multiSelectionButtons li.info .openLayer {
              display: inline-block;
              position: relative;
              left: auto;
              right: 0;
              top: 5px; }
              @media only screen and (min-width: 750px), print {
                #contentWrapper .contentS1SaunaConfigurator .tabWrapper .selectionButtons li.info .openLayer,
                .svg #contentWrapper .contentS1SaunaConfigurator .tabWrapper .selectionButtons li.info .openLayer,
                #contentWrapper .contentS1SaunaConfigurator .tabWrapper .multiSelectionButtons li.info .openLayer,
                .svg
                #contentWrapper .contentS1SaunaConfigurator .tabWrapper .multiSelectionButtons li.info .openLayer {
                  position: relative;
                  left: 0;
                  right: auto;
                  top: 50%;
                  margin-top: -2px; } }
            #contentWrapper .contentS1SaunaConfigurator .tabWrapper .selectionButtons li.info .infoLayer,
            .svg #contentWrapper .contentS1SaunaConfigurator .tabWrapper .selectionButtons li.info .infoLayer,
            #contentWrapper .contentS1SaunaConfigurator .tabWrapper .multiSelectionButtons li.info .infoLayer,
            .svg
            #contentWrapper .contentS1SaunaConfigurator .tabWrapper .multiSelectionButtons li.info .infoLayer {
              text-align: left; }
      #contentWrapper .contentS1SaunaConfigurator .tabWrapper .multiSelectionButtons li,
      .svg #contentWrapper .contentS1SaunaConfigurator .tabWrapper .multiSelectionButtons li {
        position: relative; }
        #contentWrapper .contentS1SaunaConfigurator .tabWrapper .multiSelectionButtons li a,
        .svg #contentWrapper .contentS1SaunaConfigurator .tabWrapper .multiSelectionButtons li a {
          padding: 10px 50px 10px 15px; }
          #contentWrapper .contentS1SaunaConfigurator .tabWrapper .multiSelectionButtons li a:after,
          .svg #contentWrapper .contentS1SaunaConfigurator .tabWrapper .multiSelectionButtons li a:after {
            content: "";
            display: block;
            height: 30px;
            margin-top: -15px;
            position: absolute;
            right: 8px;
            top: 50%;
            width: 30px;
            background-image: url(/fileadmin/images/microsites/s1/icons-s1-konfigurator.png);
            background-color: transparent;
            background-position: -9999px -9999px;
            background-repeat: no-repeat;
            background-position: -50px -200px; }
            .svg #contentWrapper .contentS1SaunaConfigurator .tabWrapper .multiSelectionButtons li a:after, .svg
            .svg #contentWrapper .contentS1SaunaConfigurator .tabWrapper .multiSelectionButtons li a:after {
              background-image: url(/fileadmin/images/microsites/s1/icons-s1-konfigurator.svg); }
          #contentWrapper .contentS1SaunaConfigurator .tabWrapper .multiSelectionButtons li a:hover:after, #contentWrapper .contentS1SaunaConfigurator .tabWrapper .multiSelectionButtons li a:focus:after, #contentWrapper .contentS1SaunaConfigurator .tabWrapper .multiSelectionButtons li a:active:after,
          .svg #contentWrapper .contentS1SaunaConfigurator .tabWrapper .multiSelectionButtons li a:hover:after,
          .svg #contentWrapper .contentS1SaunaConfigurator .tabWrapper .multiSelectionButtons li a:focus:after,
          .svg #contentWrapper .contentS1SaunaConfigurator .tabWrapper .multiSelectionButtons li a:active:after {
            background-position: -150px -250px; }
          #contentWrapper .contentS1SaunaConfigurator .tabWrapper .multiSelectionButtons li a.active:after,
          .svg #contentWrapper .contentS1SaunaConfigurator .tabWrapper .multiSelectionButtons li a.active:after {
            background-position: -150px -250px; }
          #contentWrapper .contentS1SaunaConfigurator .tabWrapper .multiSelectionButtons li a.active:hover:after, #contentWrapper .contentS1SaunaConfigurator .tabWrapper .multiSelectionButtons li a.active:focus:after, #contentWrapper .contentS1SaunaConfigurator .tabWrapper .multiSelectionButtons li a.active:active:after,
          .svg #contentWrapper .contentS1SaunaConfigurator .tabWrapper .multiSelectionButtons li a.active:hover:after,
          .svg #contentWrapper .contentS1SaunaConfigurator .tabWrapper .multiSelectionButtons li a.active:focus:after,
          .svg #contentWrapper .contentS1SaunaConfigurator .tabWrapper .multiSelectionButtons li a.active:active:after {
            background-position: -150px -200px; }
    @media only screen and (min-width: 1024px), print {
      #contentWrapper .contentS1SaunaConfigurator .columns {
        margin: 0 auto;
        width: 696px; } }
    @media only screen and (min-width: 1280px), print {
      #contentWrapper .contentS1SaunaConfigurator .columns {
        width: 806.666666667px; } }
    #contentWrapper .contentS1SaunaConfigurator .columns .selection {
      margin: 0 auto 45px;
      max-width: 400px;
      position: relative; }
      @media only screen and (min-width: 1024px), print {
        #contentWrapper .contentS1SaunaConfigurator .columns .selection {
          float: left;
          max-width: none;
          padding: 0 15px;
          width: 50%; } }
      #contentWrapper .contentS1SaunaConfigurator .columns .selection .selectionContent {
        border: 2px solid #ccc0a7;
        display: block;
        min-height: 210px;
        padding: 15px 15px 30px;
        position: relative;
        text-decoration: none;
        background-color: #ffffff;
        color: #000000; }
        #contentWrapper .contentS1SaunaConfigurator .columns .selection .selectionContent.hover, #contentWrapper .contentS1SaunaConfigurator .columns .selection .selectionContent:hover {
          background-color: #ccc0a7; }
        #contentWrapper .contentS1SaunaConfigurator .columns .selection .selectionContent.current, #contentWrapper .contentS1SaunaConfigurator .columns .selection .selectionContent.act, #contentWrapper .contentS1SaunaConfigurator .columns .selection .selectionContent.active, #contentWrapper .contentS1SaunaConfigurator .columns .selection .selectionContent:active {
          background-color: #ccc0a7; }
        #contentWrapper .contentS1SaunaConfigurator .columns .selection .selectionContent.focus, #contentWrapper .contentS1SaunaConfigurator .columns .selection .selectionContent:focus {
          background-color: #ccc0a7; }
        #contentWrapper .contentS1SaunaConfigurator .columns .selection .selectionContent.hover, #contentWrapper .contentS1SaunaConfigurator .columns .selection .selectionContent:hover {
          color: #ffffff; }
        #contentWrapper .contentS1SaunaConfigurator .columns .selection .selectionContent.current, #contentWrapper .contentS1SaunaConfigurator .columns .selection .selectionContent.act, #contentWrapper .contentS1SaunaConfigurator .columns .selection .selectionContent.active, #contentWrapper .contentS1SaunaConfigurator .columns .selection .selectionContent:active {
          color: #ffffff; }
        #contentWrapper .contentS1SaunaConfigurator .columns .selection .selectionContent.focus, #contentWrapper .contentS1SaunaConfigurator .columns .selection .selectionContent:focus {
          color: #ffffff; }
        #contentWrapper .contentS1SaunaConfigurator .columns .selection .selectionContent:hover p.more, #contentWrapper .contentS1SaunaConfigurator .columns .selection .selectionContent:focus p.more, #contentWrapper .contentS1SaunaConfigurator .columns .selection .selectionContent:active p.more {
          background-color: #5e584d;
          border-color: #5e584d;
          color: #ffffff; }
          #contentWrapper .contentS1SaunaConfigurator .columns .selection .selectionContent:hover p.more:before, #contentWrapper .contentS1SaunaConfigurator .columns .selection .selectionContent:focus p.more:before, #contentWrapper .contentS1SaunaConfigurator .columns .selection .selectionContent:active p.more:before {
            color: #ffffff; }
      #contentWrapper .contentS1SaunaConfigurator .columns .selection.voltage .selectionContent:after {
        bottom: 15px;
        content: "";
        display: block;
        height: 40px;
        position: absolute;
        right: 15px;
        width: 40px;
        background-image: url(/fileadmin/images/microsites/s1/icons-s1-konfigurator.png);
        background-color: transparent;
        background-position: -9999px -9999px;
        background-repeat: no-repeat; }
        .svg #contentWrapper .contentS1SaunaConfigurator .columns .selection.voltage .selectionContent:after {
          background-image: url(/fileadmin/images/microsites/s1/icons-s1-konfigurator.svg); }
      #contentWrapper .contentS1SaunaConfigurator .columns .selection.voltage230 .selectionContent:after {
        background-position: -450px -200px; }
      #contentWrapper .contentS1SaunaConfigurator .columns .selection.voltage230 .selectionContent:hover:after, #contentWrapper .contentS1SaunaConfigurator .columns .selection.voltage230 .selectionContent:focus:after, #contentWrapper .contentS1SaunaConfigurator .columns .selection.voltage230 .selectionContent:active:after {
        background-position: -500px -200px; }
      #contentWrapper .contentS1SaunaConfigurator .columns .selection.voltage400 .selectionContent:after {
        background-position: -450px -150px; }
      #contentWrapper .contentS1SaunaConfigurator .columns .selection.voltage400 .selectionContent:hover:after, #contentWrapper .contentS1SaunaConfigurator .columns .selection.voltage400 .selectionContent:focus:after, #contentWrapper .contentS1SaunaConfigurator .columns .selection.voltage400 .selectionContent:active:after {
        background-position: -500px -150px; }
      #contentWrapper .contentS1SaunaConfigurator .columns .selection p {
        margin-bottom: 0; }
        #contentWrapper .contentS1SaunaConfigurator .columns .selection p.more {
          border: 2px solid #ccc0a7;
          bottom: -22px;
          height: 44px;
          left: 50%;
          margin-left: -22px;
          position: absolute;
          width: 44px;
          -moz-border-radius: radius / radius;
          -webkit-border-radius: radius radius;
          border-radius: radius / radius;
          background-color: #ffffff;
          border-color: #ccc0a7;
          color: #000000;
          text-indent: -9999px;
          white-space: nowrap;
          overflow: hidden; }
          #contentWrapper .contentS1SaunaConfigurator .columns .selection p.more.hover, #contentWrapper .contentS1SaunaConfigurator .columns .selection p.more:hover {
            background-color: #5e584d; }
          #contentWrapper .contentS1SaunaConfigurator .columns .selection p.more.current, #contentWrapper .contentS1SaunaConfigurator .columns .selection p.more.act, #contentWrapper .contentS1SaunaConfigurator .columns .selection p.more.active, #contentWrapper .contentS1SaunaConfigurator .columns .selection p.more:active {
            background-color: #5e584d; }
          #contentWrapper .contentS1SaunaConfigurator .columns .selection p.more.focus, #contentWrapper .contentS1SaunaConfigurator .columns .selection p.more:focus {
            background-color: #5e584d; }
          #contentWrapper .contentS1SaunaConfigurator .columns .selection p.more.hover, #contentWrapper .contentS1SaunaConfigurator .columns .selection p.more:hover {
            border-color: #5e584d; }
          #contentWrapper .contentS1SaunaConfigurator .columns .selection p.more.current, #contentWrapper .contentS1SaunaConfigurator .columns .selection p.more.act, #contentWrapper .contentS1SaunaConfigurator .columns .selection p.more.active, #contentWrapper .contentS1SaunaConfigurator .columns .selection p.more:active {
            border-color: #5e584d; }
          #contentWrapper .contentS1SaunaConfigurator .columns .selection p.more.focus, #contentWrapper .contentS1SaunaConfigurator .columns .selection p.more:focus {
            border-color: #5e584d; }
          #contentWrapper .contentS1SaunaConfigurator .columns .selection p.more.hover, #contentWrapper .contentS1SaunaConfigurator .columns .selection p.more:hover {
            color: #ffffff; }
          #contentWrapper .contentS1SaunaConfigurator .columns .selection p.more.current, #contentWrapper .contentS1SaunaConfigurator .columns .selection p.more.act, #contentWrapper .contentS1SaunaConfigurator .columns .selection p.more.active, #contentWrapper .contentS1SaunaConfigurator .columns .selection p.more:active {
            color: #ffffff; }
          #contentWrapper .contentS1SaunaConfigurator .columns .selection p.more.focus, #contentWrapper .contentS1SaunaConfigurator .columns .selection p.more:focus {
            color: #ffffff; }
          #contentWrapper .contentS1SaunaConfigurator .columns .selection p.more:before {
            color: #000000;
            content: "›";
            display: block;
            font-size: 2em;
            height: 40px;
            left: 0;
            line-height: 34px;
            position: absolute;
            text-align: center;
            top: 0;
            width: 40px;
            font-family: 'Futura PT Multilingual W02', Helvetica, Arial, sans-serif;
            font-weight: 400;
            font-style: normal;
            text-indent: 0;
            white-space: normal;
            overflow: visible; }
      #contentWrapper .contentS1SaunaConfigurator .columns .selection.selectionTeaser .selectionContent {
        background-color: #f2efe9;
        color: #000000; }
        #contentWrapper .contentS1SaunaConfigurator .columns .selection.selectionTeaser .selectionContent.hover, #contentWrapper .contentS1SaunaConfigurator .columns .selection.selectionTeaser .selectionContent:hover {
          background-color: #f2efe9; }
        #contentWrapper .contentS1SaunaConfigurator .columns .selection.selectionTeaser .selectionContent.current, #contentWrapper .contentS1SaunaConfigurator .columns .selection.selectionTeaser .selectionContent.act, #contentWrapper .contentS1SaunaConfigurator .columns .selection.selectionTeaser .selectionContent.active, #contentWrapper .contentS1SaunaConfigurator .columns .selection.selectionTeaser .selectionContent:active {
          background-color: #f2efe9; }
        #contentWrapper .contentS1SaunaConfigurator .columns .selection.selectionTeaser .selectionContent.focus, #contentWrapper .contentS1SaunaConfigurator .columns .selection.selectionTeaser .selectionContent:focus {
          background-color: #f2efe9; }
        #contentWrapper .contentS1SaunaConfigurator .columns .selection.selectionTeaser .selectionContent.hover, #contentWrapper .contentS1SaunaConfigurator .columns .selection.selectionTeaser .selectionContent:hover {
          color: #000000; }
        #contentWrapper .contentS1SaunaConfigurator .columns .selection.selectionTeaser .selectionContent.current, #contentWrapper .contentS1SaunaConfigurator .columns .selection.selectionTeaser .selectionContent.act, #contentWrapper .contentS1SaunaConfigurator .columns .selection.selectionTeaser .selectionContent.active, #contentWrapper .contentS1SaunaConfigurator .columns .selection.selectionTeaser .selectionContent:active {
          color: #000000; }
        #contentWrapper .contentS1SaunaConfigurator .columns .selection.selectionTeaser .selectionContent.focus, #contentWrapper .contentS1SaunaConfigurator .columns .selection.selectionTeaser .selectionContent:focus {
          color: #000000; }
      #contentWrapper .contentS1SaunaConfigurator .columns .selection.selectionTeaser a {
        float: right;
        color: #000000; }
        #contentWrapper .contentS1SaunaConfigurator .columns .selection.selectionTeaser a.hover, #contentWrapper .contentS1SaunaConfigurator .columns .selection.selectionTeaser a:hover {
          color: #000000; }
        #contentWrapper .contentS1SaunaConfigurator .columns .selection.selectionTeaser a.current, #contentWrapper .contentS1SaunaConfigurator .columns .selection.selectionTeaser a.act, #contentWrapper .contentS1SaunaConfigurator .columns .selection.selectionTeaser a.active, #contentWrapper .contentS1SaunaConfigurator .columns .selection.selectionTeaser a:active {
          color: #000000; }
        #contentWrapper .contentS1SaunaConfigurator .columns .selection.selectionTeaser a.focus, #contentWrapper .contentS1SaunaConfigurator .columns .selection.selectionTeaser a:focus {
          color: #000000; }
      #contentWrapper .contentS1SaunaConfigurator .columns .selection dl {
        float: none;
        margin: 0; }
        #contentWrapper .contentS1SaunaConfigurator .columns .selection dl dt,
        #contentWrapper .contentS1SaunaConfigurator .columns .selection dl dd {
          display: block;
          float: left;
          padding: 0; }
        #contentWrapper .contentS1SaunaConfigurator .columns .selection dl dt {
          clear: left;
          margin-right: 10px; }
        #contentWrapper .contentS1SaunaConfigurator .columns .selection dl dd {
          clear: right; }
    @media only screen and (min-width: 1024px), print {
      #contentWrapper .contentS1SaunaConfigurator .columns.columns3 {
        margin: 0 -15px;
        width: 954px; }
        #contentWrapper .contentS1SaunaConfigurator .columns.columns3 .selection {
          width: 33.3333333333%; } }
    @media only screen and (min-width: 1280px), print {
      #contentWrapper .contentS1SaunaConfigurator .columns.columns3 {
        width: 1210px; } }
    #contentWrapper .contentS1SaunaConfigurator .configurationWrapper {
      margin: 0 auto;
      max-width: 700px; }
      @media only screen and (min-width: 1024px), print {
        #contentWrapper .contentS1SaunaConfigurator .configurationWrapper {
          margin: 0 -15px;
          max-width: none;
          width: 954px; } }
      @media only screen and (min-width: 1280px), print {
        #contentWrapper .contentS1SaunaConfigurator .configurationWrapper {
          width: 1210px; } }
      #contentWrapper .contentS1SaunaConfigurator .configurationWrapper .column {
        width: 100%; }
        @media only screen and (min-width: 1024px), print {
          #contentWrapper .contentS1SaunaConfigurator .configurationWrapper .column {
            float: left;
            margin: 0;
            padding: 0 15px;
            width: 50%; } }
    #contentWrapper .contentS1SaunaConfigurator .openLayer {
      border: 2px solid #ccc0a7;
      bottom: -10px;
      cursor: pointer;
      display: block;
      font-size: 1.2em;
      height: 30px;
      position: absolute;
      right: -10px;
      width: 30px;
      z-index: 1;
      -moz-border-radius: radius / radius;
      -webkit-border-radius: radius radius;
      border-radius: radius / radius;
      background-image: url(/fileadmin/images/microsites/s1/icons-s1.png);
      background-color: transparent;
      background-position: -9999px -9999px;
      background-repeat: no-repeat;
      background-position: -787px -657px;
      background-color: #ffffff;
      border-color: #ccc0a7;
      color: #000000;
      font-family: 'Futura PT Multilingual W02', Helvetica, Arial, sans-serif;
      font-weight: 400;
      font-style: normal;
      text-indent: -9999px;
      white-space: nowrap;
      overflow: hidden; }
      .svg #contentWrapper .contentS1SaunaConfigurator .openLayer {
        background-image: url(/fileadmin/images/microsites/s1/icons-s1.svg); }
      #contentWrapper .contentS1SaunaConfigurator .openLayer.hover, #contentWrapper .contentS1SaunaConfigurator .openLayer:hover {
        background-position: -857px -657px; }
      #contentWrapper .contentS1SaunaConfigurator .openLayer.act, #contentWrapper .contentS1SaunaConfigurator .openLayer.active, #contentWrapper .contentS1SaunaConfigurator .openLayer:active {
        background-position: -857px -657px; }
      #contentWrapper .contentS1SaunaConfigurator .openLayer.focus, #contentWrapper .contentS1SaunaConfigurator .openLayer:focus {
        background-position: -857px -657px; }
      #contentWrapper .contentS1SaunaConfigurator .openLayer.hover, #contentWrapper .contentS1SaunaConfigurator .openLayer:hover {
        background-color: #5e584d; }
      #contentWrapper .contentS1SaunaConfigurator .openLayer.current, #contentWrapper .contentS1SaunaConfigurator .openLayer.act, #contentWrapper .contentS1SaunaConfigurator .openLayer.active, #contentWrapper .contentS1SaunaConfigurator .openLayer:active {
        background-color: #5e584d; }
      #contentWrapper .contentS1SaunaConfigurator .openLayer.focus, #contentWrapper .contentS1SaunaConfigurator .openLayer:focus {
        background-color: #5e584d; }
      #contentWrapper .contentS1SaunaConfigurator .openLayer.hover, #contentWrapper .contentS1SaunaConfigurator .openLayer:hover {
        border-color: #5e584d; }
      #contentWrapper .contentS1SaunaConfigurator .openLayer.current, #contentWrapper .contentS1SaunaConfigurator .openLayer.act, #contentWrapper .contentS1SaunaConfigurator .openLayer.active, #contentWrapper .contentS1SaunaConfigurator .openLayer:active {
        border-color: #5e584d; }
      #contentWrapper .contentS1SaunaConfigurator .openLayer.focus, #contentWrapper .contentS1SaunaConfigurator .openLayer:focus {
        border-color: #5e584d; }
      #contentWrapper .contentS1SaunaConfigurator .openLayer.hover, #contentWrapper .contentS1SaunaConfigurator .openLayer:hover {
        color: #ffffff; }
      #contentWrapper .contentS1SaunaConfigurator .openLayer.current, #contentWrapper .contentS1SaunaConfigurator .openLayer.act, #contentWrapper .contentS1SaunaConfigurator .openLayer.active, #contentWrapper .contentS1SaunaConfigurator .openLayer:active {
        color: #ffffff; }
      #contentWrapper .contentS1SaunaConfigurator .openLayer.focus, #contentWrapper .contentS1SaunaConfigurator .openLayer:focus {
        color: #ffffff; }
      @media only screen and (min-width: 1024px), print {
        #contentWrapper .contentS1SaunaConfigurator .openLayer {
          right: 5px; } }
    #contentWrapper .contentS1SaunaConfigurator .tabWrapper .openLayer {
      right: 10px;
      top: 10px; }
    #contentWrapper .contentS1SaunaConfigurator .infoLayer,
    #contentWrapper .contentS1SaunaConfigurator .closeLayer {
      display: none; }
    #contentWrapper .contentS1SaunaConfigurator .infoLayerBG {
      background: rgba(0, 0, 0, 0.75);
      border: 1px solid #ffffff;
      display: none;
      height: 100%;
      left: 0;
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 31; }
    #contentWrapper .contentS1SaunaConfigurator .closeLayer {
      border: 2px solid #ccc0a7;
      background: none;
      display: block;
      height: 30px;
      position: absolute;
      right: 10px;
      top: 10px;
      width: 30px;
      z-index: 33;
      -moz-border-radius: radius / radius;
      -webkit-border-radius: radius radius;
      border-radius: radius / radius;
      background-color: #ffffff;
      border-color: #ccc0a7;
      color: #000000;
      text-indent: -9999px;
      white-space: nowrap;
      overflow: hidden; }
      #contentWrapper .contentS1SaunaConfigurator .closeLayer.hover, #contentWrapper .contentS1SaunaConfigurator .closeLayer:hover {
        background-color: #5e584d; }
      #contentWrapper .contentS1SaunaConfigurator .closeLayer.current, #contentWrapper .contentS1SaunaConfigurator .closeLayer.act, #contentWrapper .contentS1SaunaConfigurator .closeLayer.active, #contentWrapper .contentS1SaunaConfigurator .closeLayer:active {
        background-color: #5e584d; }
      #contentWrapper .contentS1SaunaConfigurator .closeLayer.focus, #contentWrapper .contentS1SaunaConfigurator .closeLayer:focus {
        background-color: #5e584d; }
      #contentWrapper .contentS1SaunaConfigurator .closeLayer.hover, #contentWrapper .contentS1SaunaConfigurator .closeLayer:hover {
        border-color: #5e584d; }
      #contentWrapper .contentS1SaunaConfigurator .closeLayer.current, #contentWrapper .contentS1SaunaConfigurator .closeLayer.act, #contentWrapper .contentS1SaunaConfigurator .closeLayer.active, #contentWrapper .contentS1SaunaConfigurator .closeLayer:active {
        border-color: #5e584d; }
      #contentWrapper .contentS1SaunaConfigurator .closeLayer.focus, #contentWrapper .contentS1SaunaConfigurator .closeLayer:focus {
        border-color: #5e584d; }
      #contentWrapper .contentS1SaunaConfigurator .closeLayer.hover, #contentWrapper .contentS1SaunaConfigurator .closeLayer:hover {
        color: #ffffff; }
      #contentWrapper .contentS1SaunaConfigurator .closeLayer.current, #contentWrapper .contentS1SaunaConfigurator .closeLayer.act, #contentWrapper .contentS1SaunaConfigurator .closeLayer.active, #contentWrapper .contentS1SaunaConfigurator .closeLayer:active {
        color: #ffffff; }
      #contentWrapper .contentS1SaunaConfigurator .closeLayer.focus, #contentWrapper .contentS1SaunaConfigurator .closeLayer:focus {
        color: #ffffff; }
      #contentWrapper .contentS1SaunaConfigurator .closeLayer:after {
        content: "";
        height: 14px;
        left: 6px;
        position: absolute;
        top: 6px;
        width: 14px;
        -moz-border-radius: radius / radius;
        -webkit-border-radius: radius radius;
        border-radius: radius / radius;
        background-image: url(/fileadmin/images/microsites/s1/sprite.png);
        background-color: transparent;
        background-position: -9999px -9999px;
        background-repeat: no-repeat;
        background-position: -315px -215px; }
        .svg #contentWrapper .contentS1SaunaConfigurator .closeLayer:after {
          background-image: url(/fileadmin/images/microsites/s1/sprite.svg); }
      #contentWrapper .contentS1SaunaConfigurator .closeLayer:hover, #contentWrapper .contentS1SaunaConfigurator .closeLayer:active, #contentWrapper .contentS1SaunaConfigurator .closeLayer:focus {
        background-image: none; }
        #contentWrapper .contentS1SaunaConfigurator .closeLayer:hover:after, #contentWrapper .contentS1SaunaConfigurator .closeLayer:active:after, #contentWrapper .contentS1SaunaConfigurator .closeLayer:focus:after {
          background-position: -215px -215px; }
    #contentWrapper .contentS1SaunaConfigurator .infoLayer {
      background: #f2efe9;
      height: 250px;
      left: 0;
      margin-top: -125px;
      padding: 30px 30px 0;
      position: fixed;
      top: 50%;
      width: 100%;
      z-index: 32; }
      #contentWrapper .contentS1SaunaConfigurator .infoLayer .layerContent {
        height: 200px;
        overflow: hidden; }
        @media only screen and (min-width: 750px), print {
          #contentWrapper .contentS1SaunaConfigurator .infoLayer .layerContent {
            height: 200px; } }
      #contentWrapper .contentS1SaunaConfigurator .infoLayer h3 {
        margin-bottom: 10px;
        padding-right: 30px; }
      @media only screen and (min-width: 750px), print {
        #contentWrapper .contentS1SaunaConfigurator .infoLayer {
          height: 250px;
          left: 50%;
          margin: -175px 0 0 -250px;
          width: 500px; } }
    #contentWrapper .contentS1SaunaConfigurator .scroll-content-vertical {
      padding-right: 10px; }
    #contentWrapper .contentS1SaunaConfigurator .scroll-bar-wrap-vertical {
      display: none;
      border-left: 2px solid #000000;
      width: 10px; }
      #contentWrapper .contentS1SaunaConfigurator .scroll-bar-wrap-vertical .ui-slider {
        background: transparent;
        width: 0px; }
      #contentWrapper .contentS1SaunaConfigurator .scroll-bar-wrap-vertical .ui-handle-helper-parent {
        width: 1px; }
      #contentWrapper .contentS1SaunaConfigurator .scroll-bar-wrap-vertical .ui-slider-handle {
        background-color: #ccc0a7;
        cursor: pointer;
        margin-left: -6px;
        margin-bottom: 0;
        width: 10px;
        -moz-border-radius: radius / radius;
        -webkit-border-radius: radius radius;
        border-radius: radius / radius;
        background-color: #ccc0a7; }
        #contentWrapper .contentS1SaunaConfigurator .scroll-bar-wrap-vertical .ui-slider-handle.hover, #contentWrapper .contentS1SaunaConfigurator .scroll-bar-wrap-vertical .ui-slider-handle:hover {
          background-color: #000000; }
        #contentWrapper .contentS1SaunaConfigurator .scroll-bar-wrap-vertical .ui-slider-handle.current, #contentWrapper .contentS1SaunaConfigurator .scroll-bar-wrap-vertical .ui-slider-handle.act, #contentWrapper .contentS1SaunaConfigurator .scroll-bar-wrap-vertical .ui-slider-handle.active, #contentWrapper .contentS1SaunaConfigurator .scroll-bar-wrap-vertical .ui-slider-handle:active {
          background-color: #000000; }
        #contentWrapper .contentS1SaunaConfigurator .scroll-bar-wrap-vertical .ui-slider-handle.focus, #contentWrapper .contentS1SaunaConfigurator .scroll-bar-wrap-vertical .ui-slider-handle:focus {
          background-color: #000000; }
  @media only screen and (min-width: 750px), print {
    #contentWrapper .contentS1ShowConfiguration h1,
    #contentWrapper .contentS1SaunaConfigurator h1 {
      margin-bottom: 20px; } }
  #contentWrapper .contentS1ShowConfiguration .thumbnails,
  #contentWrapper .contentS1SaunaConfigurator .thumbnails {
    display: none;
    padding-top: 45px; }
    @media only screen and (min-width: 750px), print {
      #contentWrapper .contentS1ShowConfiguration .thumbnails,
      #contentWrapper .contentS1SaunaConfigurator .thumbnails {
        display: block;
        margin: 0 -78px; }
        #contentWrapper .contentS1ShowConfiguration .thumbnails .item,
        #contentWrapper .contentS1SaunaConfigurator .thumbnails .item {
          display: block;
          float: left;
          padding: 0;
          width: 20%; }
        #contentWrapper .contentS1ShowConfiguration .thumbnails a,
        #contentWrapper .contentS1SaunaConfigurator .thumbnails a {
          display: block;
          border: 2px solid #ccc0a7;
          margin: 15px; }
          #contentWrapper .contentS1ShowConfiguration .thumbnails a img,
          #contentWrapper .contentS1SaunaConfigurator .thumbnails a img {
            opacity: 0.75; }
          #contentWrapper .contentS1ShowConfiguration .thumbnails a:hover img, #contentWrapper .contentS1ShowConfiguration .thumbnails a:active img, #contentWrapper .contentS1ShowConfiguration .thumbnails a:focus img,
          #contentWrapper .contentS1SaunaConfigurator .thumbnails a:hover img,
          #contentWrapper .contentS1SaunaConfigurator .thumbnails a:active img,
          #contentWrapper .contentS1SaunaConfigurator .thumbnails a:focus img {
            opacity: 1; } }
    @media only screen and (min-width: 1024px), print {
      #contentWrapper .contentS1ShowConfiguration .thumbnails,
      #contentWrapper .contentS1SaunaConfigurator .thumbnails {
        margin: 0 -15px; } }
  #contentWrapper .contentS1ShowConfiguration .thumbnailSlider .rsSlide,
  #contentWrapper .contentS1SaunaConfigurator .thumbnailSlider .rsSlide {
    background: #ffffff; }
  #contentWrapper .contentS1ShowConfiguration .thumbnailSlider .rsThumbsContainer,
  #contentWrapper .contentS1SaunaConfigurator .thumbnailSlider .rsThumbsContainer {
    margin: 0 auto;
    width: 280px !important; }
    @media only screen and (min-width: 750px), print {
      #contentWrapper .contentS1ShowConfiguration .thumbnailSlider .rsThumbsContainer,
      #contentWrapper .contentS1SaunaConfigurator .thumbnailSlider .rsThumbsContainer {
        width: 380px !important; } }
  #contentWrapper .contentS1ShowConfiguration .thumbnailSlider .rsThumbs,
  #contentWrapper .contentS1SaunaConfigurator .thumbnailSlider .rsThumbs {
    margin: 0;
    width: 100%; }
  #contentWrapper .contentS1ShowConfiguration .thumbnailSlider .rsThumb,
  #contentWrapper .contentS1SaunaConfigurator .thumbnailSlider .rsThumb {
    border: 2px solid #ccc0a7;
    display: inline-block;
    float: none;
    margin: 1px 5px;
    padding: 5px;
    width: 60px; }
    @media only screen and (min-width: 750px), print {
      #contentWrapper .contentS1ShowConfiguration .thumbnailSlider .rsThumb,
      #contentWrapper .contentS1SaunaConfigurator .thumbnailSlider .rsThumb {
        margin: 6px 10px;
        width: 75px; } }
    #contentWrapper .contentS1ShowConfiguration .thumbnailSlider .rsThumb img,
    #contentWrapper .contentS1SaunaConfigurator .thumbnailSlider .rsThumb img {
      opacity: 0.5; }
    #contentWrapper .contentS1ShowConfiguration .thumbnailSlider .rsThumb.rsNavSelected img,
    #contentWrapper .contentS1SaunaConfigurator .thumbnailSlider .rsThumb.rsNavSelected img {
      opacity: 1; }
  #contentWrapper .contentS1ShowConfiguration .thumbnailSlider .rsArrow,
  #contentWrapper .contentS1SaunaConfigurator .thumbnailSlider .rsArrow {
    position: absolute;
    width: 50px;
    height: 100px;
    top: 50%;
    margin-top: -50px;
    z-index: 11; }
    #contentWrapper .contentS1ShowConfiguration .thumbnailSlider .rsArrow:before,
    #contentWrapper .contentS1SaunaConfigurator .thumbnailSlider .rsArrow:before {
      display: block;
      line-height: 65px;
      font-size: 8em;
      color: #ccc0a7; }
    #contentWrapper .contentS1ShowConfiguration .thumbnailSlider .rsArrow:hover:before, #contentWrapper .contentS1ShowConfiguration .thumbnailSlider .rsArrow:focus:before, #contentWrapper .contentS1ShowConfiguration .thumbnailSlider .rsArrow:active:before,
    #contentWrapper .contentS1SaunaConfigurator .thumbnailSlider .rsArrow:hover:before,
    #contentWrapper .contentS1SaunaConfigurator .thumbnailSlider .rsArrow:focus:before,
    #contentWrapper .contentS1SaunaConfigurator .thumbnailSlider .rsArrow:active:before {
      color: #5e584d; }
  #contentWrapper .contentS1ShowConfiguration .thumbnailSlider .rsArrowLeft,
  #contentWrapper .contentS1SaunaConfigurator .thumbnailSlider .rsArrowLeft {
    left: 0; }
    #contentWrapper .contentS1ShowConfiguration .thumbnailSlider .rsArrowLeft:before,
    #contentWrapper .contentS1SaunaConfigurator .thumbnailSlider .rsArrowLeft:before {
      content: "‹"; }
  #contentWrapper .contentS1ShowConfiguration .thumbnailSlider .rsArrowRight,
  #contentWrapper .contentS1SaunaConfigurator .thumbnailSlider .rsArrowRight {
    right: 0; }
    #contentWrapper .contentS1ShowConfiguration .thumbnailSlider .rsArrowRight:before,
    #contentWrapper .contentS1SaunaConfigurator .thumbnailSlider .rsArrowRight:before {
      content: "›";
      text-align: right; }
  #contentWrapper .contentS1ShowConfiguration .thumbnailSlider .showConfiguration,
  #contentWrapper .contentS1SaunaConfigurator .thumbnailSlider .showConfiguration {
    display: block !important;
    margin-bottom: 15px !important; }
    #contentWrapper .contentS1ShowConfiguration .thumbnailSlider .showConfiguration:after,
    #contentWrapper .contentS1SaunaConfigurator .thumbnailSlider .showConfiguration:after {
      bottom: 7%; }
  #contentWrapper .contentS1ShowConfiguration {
    padding-top: 40px; }
    #contentWrapper .contentS1ShowConfiguration *:focus, #contentWrapper .contentS1ShowConfiguration *:active {
      outline: none; }
    #pageInLayer #contentWrapper .contentS1ShowConfiguration {
      padding-top: 0; }
    #contentWrapper .contentS1ShowConfiguration .configurationWrapper .previewImage .showConfiguration {
      display: none; }
      @media only screen and (min-width: 750px), print {
        #contentWrapper .contentS1ShowConfiguration .configurationWrapper .previewImage .showConfiguration {
          display: block;
          margin-bottom: 60px; } }
    #contentWrapper .contentS1ShowConfiguration .configurationWrapper .previewImage .switch {
      bottom: 0;
      top: auto;
      left: 50%;
      margin-left: -38px;
      z-index: 1; }
      @media only screen and (min-width: 750px), print {
        #contentWrapper .contentS1ShowConfiguration .configurationWrapper .previewImage .switch {
          bottom: auto;
          top: 340px; } }
      @media only screen and (min-width: 1024px), print {
        #contentWrapper .contentS1ShowConfiguration .configurationWrapper .previewImage .switch {
          top: 330px; } }
      @media only screen and (min-width: 1280px), print {
        #contentWrapper .contentS1ShowConfiguration .configurationWrapper .previewImage .switch {
          top: 375px; } }
    #contentWrapper .contentS1ShowConfiguration .configurationWrapper .thumbnails {
      display: block;
      padding: 0;
      margin-bottom: 60px; }
      @media only screen and (min-width: 1024px), print {
        #contentWrapper .contentS1ShowConfiguration .configurationWrapper .thumbnails {
          margin: 0 -15px 60px; } }
      #contentWrapper .contentS1ShowConfiguration .configurationWrapper .thumbnails li,
      .svg #contentWrapper .contentS1ShowConfiguration .configurationWrapper .thumbnails li {
        background: none;
        padding: 0; }
      #contentWrapper .contentS1ShowConfiguration .configurationWrapper .thumbnails .rsBullets {
        position: absolute;
        left: 0;
        top: 20px;
        width: 100%;
        text-align: center;
        padding-bottom: 5px; }
        #contentWrapper .contentS1ShowConfiguration .configurationWrapper .thumbnails .rsBullets .rsBullet {
          display: inline-block;
          line-height: 0;
          width: 12px;
          height: 12px;
          margin: 0 10px;
          -moz-border-radius: radius / radius;
          -webkit-border-radius: radius radius;
          border-radius: radius / radius;
          border: 2px solid #ccc0a7;
          background-color: transparent; }
          #contentWrapper .contentS1ShowConfiguration .configurationWrapper .thumbnails .rsBullets .rsBullet.hover, #contentWrapper .contentS1ShowConfiguration .configurationWrapper .thumbnails .rsBullets .rsBullet:hover {
            background-color: #ccc0a7; }
          #contentWrapper .contentS1ShowConfiguration .configurationWrapper .thumbnails .rsBullets .rsBullet.current, #contentWrapper .contentS1ShowConfiguration .configurationWrapper .thumbnails .rsBullets .rsBullet.act, #contentWrapper .contentS1ShowConfiguration .configurationWrapper .thumbnails .rsBullets .rsBullet.active, #contentWrapper .contentS1ShowConfiguration .configurationWrapper .thumbnails .rsBullets .rsBullet:active {
            background-color: #ccc0a7; }
          #contentWrapper .contentS1ShowConfiguration .configurationWrapper .thumbnails .rsBullets .rsBullet.focus, #contentWrapper .contentS1ShowConfiguration .configurationWrapper .thumbnails .rsBullets .rsBullet:focus {
            background-color: #ccc0a7; }
          #contentWrapper .contentS1ShowConfiguration .configurationWrapper .thumbnails .rsBullets .rsBullet.rsNavSelected {
            background-color: #ccc0a7; }
      #contentWrapper .contentS1ShowConfiguration .configurationWrapper .thumbnails .rsArrow {
        position: absolute;
        width: 50px;
        height: 100px;
        top: 50%;
        margin-top: -50px; }
        #contentWrapper .contentS1ShowConfiguration .configurationWrapper .thumbnails .rsArrow:before {
          display: block;
          line-height: 65px;
          font-size: 8em;
          color: #ccc0a7; }
        #contentWrapper .contentS1ShowConfiguration .configurationWrapper .thumbnails .rsArrow:hover:before, #contentWrapper .contentS1ShowConfiguration .configurationWrapper .thumbnails .rsArrow:focus:before, #contentWrapper .contentS1ShowConfiguration .configurationWrapper .thumbnails .rsArrow:active:before {
          color: #5e584d; }
      #contentWrapper .contentS1ShowConfiguration .configurationWrapper .thumbnails .rsArrowLeft {
        left: 0; }
        #contentWrapper .contentS1ShowConfiguration .configurationWrapper .thumbnails .rsArrowLeft:before {
          content: "‹"; }
      #contentWrapper .contentS1ShowConfiguration .configurationWrapper .thumbnails .rsArrowRight {
        right: 0; }
        #contentWrapper .contentS1ShowConfiguration .configurationWrapper .thumbnails .rsArrowRight:before {
          content: "›";
          text-align: right; }
      #contentWrapper .contentS1ShowConfiguration .configurationWrapper .thumbnails .rsArrowDisabled {
        display: none !important; }
    #contentWrapper .contentS1ShowConfiguration .configurationWrapper .buttonWrapper {
      width: 100%; }

@keyframes S1SaunaConfiguratorSteps {
  50% {
    background-color: #f2efe9;
    border-color: #f2efe9; } }

@keyframes S1SaunaConfiguratorButton {
  50% {
    background-color: #ccc0a7;
    border-color: #ccc0a7; } }

@keyframes S1SaunaConfiguratorButtonCircle {
  50% { } }

@keyframes S1SaunaConfiguratorTabArrow {
  50% { } }
