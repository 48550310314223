.contentS1Configurator {
  padding-bottom: (2 * $CEBottom);

  > .innerContent {

    @media #{$mediaMin768} {
      max-width: 768px;
    }
  }

  .configuratorIFrame {
    display: none;

    @media #{$mediaMin768} {
      display: block;
    }
  }

  .contentGallery,
  p.bodytext {
    @media #{$mediaMin768} {
      display: none;
    }
  }
}

/* SAUNAKONFIGURATOR NEU by schalk&friends */

#contentWrapper {
  .contentFullScreenS1SaunaConfigurator,
  .contentS1SaunaConfigurator {

    * {
      &:focus,
      &:active {
        outline: none;
      }
    }

    /*.innerContent {
      background: $colorLightestGray;
    }*/

    .hideMobile {
      display: none !important;

      @media #{$mediaMin750} {
        display: block !important;
      }
    }

    .showMobile {
      display: block !important;

      @media #{$mediaMin750} {
        display: none !important;
      }
    }

    .hideMobileTable {
      display: none !important;

      @media #{$mediaMin750} {
        display: table !important;
      }
    }

    .showMobileTable {
      display: table !important;

      @media #{$mediaMin750} {
        display: none !important;
      }
    }

    .steps {
      font-size: (16em/18);
      padding: 15px 0 0 0;

      @media #{$mediaMin750} {
        font-size: (18em/18);
        margin: 0 auto 30px;
        max-width: 700px;
        padding: 45px 0 0 0;
      }

      .svg & li,
      li {
        background: none;
        color: $colorWhite;
        display: block;
        padding-left: 0;
        text-shadow: 0 0 1px $colorBlack, 0 0 80px $colorBlack;
        width: 100%;
        @include sfFutura('Medium');

        .lt-ie10 & {
          filter: progid:DXImageTransform.Microsoft.Glow(color=#666666, strength=1);
        }

        .safari & {
          text-shadow: 0 0 1px $colorBlack;
        }

        @media #{$mediaMin750} {
          float: left;
          width: (100%/6);
        }

        span {
          border: 2px solid $colorWhite;
          display: block;
          height: 34px;
          line-height: 30px;
          margin: 0 auto 5px;
          position: relative;
          text-align: center;
          text-shadow: none;
          width: 34px;
          @include border-radius(50%);
          @include single-box-shadow(0px, 0 ox, 80px 0px, $colorBlack);
          @include sfColor(background, $colorWhite);
          @include sfColor(color, $colorBlack);

          .lt-ie10 & {
            filter: none;
          }

          .safari & {
            text-shadow: none;
          }

          @media #{$mediaMin750} {
            height: 44px;
            line-height: 40px;
            width: 44px;
          }

          &:before,
          &:after {
            border-left: 2px solid $colorWhite;
            content: "";
            display: block;
            left: 50%;
            margin-left: 0;
            position: absolute;
          }

          &:before {
            height: 12px;
            top: -12px;

            @media #{$mediaMin750} {
              content: normal;
            }
          }

          &:after {
            bottom: -7px;
            height: 7px;

            @media #{$mediaMin750} {
              border: none;
              border-top: 2px solid $colorWhite;
              height: 0;
              left: 100%;
              margin: -1px 0 0 2px;
              top: 50%;
              width: 200%;
            }
          }
        }

        &:first-child {
          span {
            &:before {
              content: normal;
            }
          }
        }

        &:last-child {
          span {
            &:after {
              @media #{$mediaMin750} {
                content: normal;
              }
            }
          }
        }
      }
    }

    .steps2 {
      font-size: (16em/18);
      margin: 0;
      padding: 15px 0 0 0;

      @media #{$mediaMin750} {
        font-size: (18em/18);
        margin: 0 auto 30px;
        max-width: 700px;
        padding: 90px 0 60px;
      }

      .svg & li,
      li {
        background: none;
        color: $colorWhite;
        display: block;
        margin-bottom: 30px;
        padding: 10px 0 0;
        position: relative;
        text-align: center;
        text-shadow: 0 0 1px $colorBlack, 0 0 80px $colorBlack;
        width: 100%;
        z-index: 1;
        @include box-sizing(border-box);
        @include sfFutura('Medium');

        .lt-ie10 & {
          filter: progid:DXImageTransform.Microsoft.Glow(color=#666666, strength=1);
        }

        .safari & {
          text-shadow: 0 0 1px $colorBlack;
        }

        @media #{$mediaMin750} {
          border-top: 2px solid $colorWhite;
          float: left;
          margin: 0;
          width: (100%/6);
        }

        &:before {
          display: block;
          height: 20px;
          width: 20px;
          position: absolute;
          top: -12px;
          left: 50%;
          margin-left: -10px;
          border: none;
          @include box-sizing(border-box);
          background: $colorWhite;
          z-index: 0;

          @media #{$mediaMin750} {
            content: "";
            width: auto;
            top: -6px;
            right: 2px;
            left: 2px;
            height: 10px;
            margin: 0;
          }
        }

        &:after {
          border-left: 2px solid $colorWhite;
          content: "";
          display: block;
          height: 35px;
          left: 50%;
          margin-left: -1px;
          position: absolute;
          top: -25px;

          @media #{$mediaMin750} {
            content: normal;
          }
        }

        &:first-child {
          &:after {
            content: normal;
          }
        }

        @media #{$mediaMin750} {
          &:first-child {
            &:before {
              left: 0;
            }
          }

          &:last-child {
            &:before {
              right: 0;
            }
          }
        }
      }
    }
  }

  /* Startseite */
  .contentFullScreenS1SaunaConfigurator {
    img {
      width: 250%;

      @media #{$mediaMin750} {
        width: 100%;
      }
    }

    .innerContent {
      p.button {
        padding: 0;
        max-width: none;
        min-width: 0;

        a {
          display: block;
          letter-spacing: 0.05em;
          line-height: (28em/18);
          margin: 0;
          padding: 10px 50px;
          text-decoration: none;
          @include sfColor(color, $colorBlack, $colorWhite);
          @include sfColor(background, $colorWhite, $colorDarkGrayHover);
          @include single-box-shadow(0px, 0 ox, 80px 0px, $colorBlack);
          @include keyFrameAnimation(S1SaunaConfiguratorButton, 5s, 3, alternate, 6s);
        }
      }
    }

    .steps,
    .stepsMobile {
      li,
      .svg & li {
        @for $i from 1 through 6 {
          &:nth-child(#{$i}) {
            span {
              @include keyFrameAnimation(S1SaunaConfiguratorSteps, 5s, infinite, alternate, $i + 5s);
            }
          }
        }
      }
    }
  }

  /* Steps 1 bis 6 */

  .contentS1SaunaConfigurator {
    padding-top: 0;

    > .innerContent {
      max-width: 700px;
      width: auto;

      @media #{$mediaMin750} {
        margin: 0 auto $CEBottom;
        max-width: 700px;
      }

      @media #{$mediaMin1024} {
        max-width: 924px;
      }

      @media #{$mediaMin1280} {
        max-width: 1180px;
      }
    }

    h2 {
      @include sfFutura('Medium');
      margin-bottom: 15px;
    }

    .stepsMobile {
      padding: 15px 0;
      position: relative;

      li,
      .svg & li {
        background-color: $colorWhite;
        border: 2px solid $colorGray;
        color: $colorBlack;
        font-size: 1.2em;
        height: 64px;
        line-height: 50px;
        margin: 0 auto;
        text-align: center;
        width: 64px;
        @include border-radius(50%);
        @include text-shadow(none);

        &.previous,
        &.next {
          border: none;
          color: $colorGray;
          height: auto;
          position: absolute;
          top: 15px;
          width: auto;
          z-index: 1;
          @include border-radius(0);

          &:before,
          &:after {
            display: inline-block;
            font-size: 1.5em;
            @include sfColor(color, $colorGray);
          }

          a {
            color: $colorBlack;
            text-decoration: none;
          }

          a:hover,
          a:active,
          a:focus {
            &:before,
            &:after {
              @include sfColor(color, $colorBlack);
            }
          }
        }

        &.previous {
          left: 0;
          text-align: left;

          &:before {
            content: "‹";
            margin-right: 5px;
          }
        }

        &.next {
          right: 0;
          text-align: right;

          &:after {
            content: "›";
            margin-left: 5px;
          }
        }
      }
    }

    .stepsContent {
      .svg & li,
      li {
        color: $colorBlack;
        text-align: center;
        text-shadow: none;

        .lt-ie10 & {
          filter: none;
        }

        .safari & {
          text-shadow: none;
        }

        span {
          background: $colorWhite;
          border-color: $colorGray;
          color: $colorBlack;

          &:before,
          &:after {
            border-color: $colorGray;
          }
        }

        &.active {
          span {
            background: $colorDarkGrayHover;
            border-color: $colorDarkGrayHover;
            color: $colorWhite;
          }
        }

        a {
          text-decoration: none;
          color: $colorGray;

          span {
            color: $colorWhite;
            background: $colorGray;
          }
        }
      }
    }

    .stepsContent2 {
      padding: 45px 0 0 0;

      .svg & li,
      li {
        border-top-color: $colorGray;
        color: $colorBlack;
        text-shadow: none;

        .lt-ie10 & {
          filter: none;
        }

        .safari & {
          text-shadow: none;
        }

        &:before {
          background: none;
        }

        &.active {
          &:before {
            background: $colorGray;
          }
        }

        a {
          text-decoration: none;

          &:before {
            background: $colorGray;
            border: none;
            content: "";
            display: block;
            height: 10px;
            left: 2px;
            position: absolute;
            right: 2px;
            top: -6px;
            z-index: 0;
            @include box-sizing(border-box);
          }
        }

        &:first-child {
          a:before {
            left: 0;
          }
        }

        &:last-child {
          a:before {
            right: 0;
          }
        }
      }
    }

    .tools {
      padding: 0 0 15px 0;
      position: relative;

      li,
      .svg & li {
        background: none;
        display: block;
        margin-bottom: 15px;
        padding: 0;
        text-align: left;
        @include sfFutura('Medium');

        @media #{$mediaMin750} {
          float: left;
          width: 50%;
        }

        &.back,
        &.restart {
          &:before {
            display: inline-block;
            font-size: 1.5em;
            margin-right: 5px;
            @include sfColor(color, $colorGray);
          }

          a {
            text-decoration: none;
            @include sfColor(color, $colorBlack, $colorGray);
          }

          a:hover,
          a:active,
          a:focus {
            &:before {
              @include sfColor(color, $colorBlack);
            }
          }
        }

        &.back {
          &:before {
            content: "‹";
          }
        }

        &.restart {
          @media #{$mediaMin750} {
            text-align: right;
          }

          &:before {
            content: "";
            height: 20px;
            margin-right: 5px;
            position: relative;
            top: 4px;
            width: 20px;
            @include sfBackgroundImage('svg', 'icons-s1-konfigurator', 600px 300px, $imagePathS1);
            @include sfBackgroundPosition(-450px -100px);
          }
        }
      }
    }

    .previewImage {
      margin: 0 auto 15px;
      max-width: 575px;
      position: relative;

      @media #{$mediaMin1024} {
        max-width: 445px;
      }

      @media #{$mediaMin1280} {
        max-width: 575px;
      }

      .showConfiguration {
        display: block;
        margin: 0 auto;
        height: 0;
        overflow: hidden;
        position: relative;
        width: 100%;
        padding-top: 80%;

        @media #{$mediaMin750} {
          height: 350px;
          padding: 0;
          width: 450px;
        }

        @media #{$mediaMin1024} {
          height: 0;
          padding-top: 75%;
          width: 100%;
        }

        @media #{$mediaMin1280} {
          width: 450px;
          margin: 0 auto;
          padding-top: 68.5%;
        }

        img {
          display: block;
          left: 50%;
          margin: -50% 0 0 -50%;
          position: absolute;
          top: 50%;
          width: 100%;
        }

        &:after {
          bottom: 0;
          content: "";
          display: block;
          height: 30px;
          opacity: 1;
          position: absolute;
          right: 0;
          width: 30px;
          @include sfBackgroundImage('svg', 'icons-s1-konfigurator', 600px 300px, $imagePathS1);
          @include sfBackgroundPosition(-450px 0);
        }

        p {
          color: $colorWhite;
          display: block;
          font-size: (16em/18);
          left: 50%;
          line-height: (24em/16);
          margin: -45px 0 0 -150px;
          opacity: 0;
          padding-top: 66px;
          position: absolute;
          text-align: center;
          text-decoration: none;
          text-shadow: 0 0 1px $colorBlack, 0 0 80px $colorBlack;
          top: 50%;
          width: 300px;
          z-index: 2;
          @include single-transition(opacity, 1s);
          @include sfFutura('Medium');

          &:before {
            content: "";
            display: block;
            height: 60px;
            left: 50%;
            margin-left: -30px;
            position: absolute;
            top: 0;
            width: 60px;
            @include sfBackgroundImage('svg', 'icons-s1-konfigurator', 600px 300px, $imagePathS1);
            @include sfBackgroundPosition(-200px 0);
          }
        }

        &:hover,
        &:focus,
        &:active {
          p {
            opacity: 1;
          }

          &:after {
            opacity: 0;
            @include single-transition(opacity, 1s);
          }
        }
      }

      div.showConfiguration {
        &:before,
        &:after {
          content: normal;
        }
      }

      .more {
        margin-bottom: 30px;
        padding: 0;
        text-decoration: none;
        @include sfFutura('Medium');

        &:before {
          content: normal;
        }

        &:after {
          color: #ccc0a7;
          content: "›";
          display: inline-block;
          font-size: 1.5em;
          margin-left: 5px;
        }
      }

      .switch {
        background: $colorWhite;
        border: 2px solid $colorGray;
        height: 40px;
        left: -13px;
        margin: 0 0 0 0px;
        padding: 0;
        position: absolute;
        top: 309px;
        width: 76px;
        @include border-radius(20px);

        @media #{$mediaMin1024} {
          left: -91px;
          top: 350px;
        }

        @media #{$mediaMin1280} {
          left: -25px;
          top: 355px;
        }

        li,
        .svg & li {
          background: none;
          border: 5px solid $colorWhite;
          cursor: pointer;
          display: block;
          float: left;
          height: 36px;
          padding: 0;
          position: relative;
          width: 36px;
          @include border-radius(50%);
          @include single-transition('background', 1s);

          &.active {
            background: $colorGray;

            &:hover,
            &:active,
            &:focus {
              background: $colorDarkGrayHover;
            }
          }

          span {
            display: block;
            font-size: (12em/18);
            left: 50%;
            letter-spacing: 0.05em;
            margin: 0 0 0 -70px;
            position: absolute;
            text-align: left;
            text-transform: uppercase;
            top: 40px;
            width: 140px;
            @include sfFutura('Medium');
          }

          &:last-child {
            span {
              text-align: right;
            }
          }
        }
      }
    }

    .configurationWrapper {
      .previewImage {
        .switch {
          top: 311px;

          @media #{$mediaMin1024} {
            left: 48px;
            top: 294px;
          }

          @media #{$mediaMin1280} {
            left: 49px;
            top: 355px;
          }
        }
      }
    }

    .previewConfiguration {
      table {
        border-bottom: 2px solid $colorBlack;
        border-top: 2px solid $colorBlack;
        font-size: (16em/18);
        line-height: (24em/16);
        margin-bottom: 60px;
        width: 100%;

        th,
        td {
          border: none;
          border-bottom: 1px solid $colorLineGray;
          padding: 5px 5px 5px 10px;
        }

        .big {
          th,
          td {
            background: $colorLightestGray;
          }

          th {
            letter-spacing: 0.05em;
            text-transform: uppercase;
            width: 50%;
            @include sfFutura('Medium');
          }
        }
      }

      h2,
      h3,
      p {
        margin-bottom: 0;
        text-align: center;
      }

      h3 {
        margin-bottom: 5px;
        @include sfFutura('Medium');
      }

      h2 {
        font-size: 2em;
        margin-bottom: 5px;
      }

      .more {
        padding: 0;
        text-decoration: none;
        @include sfColor(color, $colorBlack, $colorBlack);
        @include sfFutura('Medium');

        &:before {
          content: normal;
        }

        &:after {
          color: #ccc0a7;
          content: "›";
          display: inline-block;
          font-size: 1.5em;
          margin-left: 5px;
        }

        &:hover,
        &:focus,
        &:active {
          text-decoration: underline;
        }
      }
    }

    .buttonWrapper {
      margin: 0 0 50px;

      @media #{$mediaMin750} {
        margin: 0 0 50px;
      }

      @media #{$mediaMin1024} {
        margin: 0 auto 50px;
      }

      p {
        margin-bottom: 10px;
      }

      a {
        &.button {
          border: 2px solid $colorGray;
          display: block;
          letter-spacing: 0.05em;
          line-height: (28em/18);
          padding: 10px 15px;
          text-decoration: none;
          text-transform: uppercase;
          @include sfColor(background, $colorWhite, $colorDarkGrayHover);
          @include sfColor(border, $colorGray, $colorDarkGrayHover);
          @include sfColor(color, $colorBlack, $colorWhite);
          @include sfFutura('Medium');

          @media #{$mediaMin750} {
            max-width: 400px;
          }
        }

        &.buttonDark {
          @include sfColor(background, $colorLightestGray, $colorDarkGrayHover)
        }
      }
    }

    .tabWrapper {
      margin-bottom: 25px;
      position: relative;

      .tabs {
        display: none;

        @media #{$mediaMin750} {
          display: block;
          left: 0;
          position: static;
          text-align: left;
          top: 0;
        }

        li {
          @media #{$mediaMin750} {
            border: none;
            display: block;
            float: left;
            margin: 0 30px 0 0;
            padding: 0;
            position: relative;
            z-index: 2;
          }

          span,
          a {
            text-transform: none;
            display: block;

            @media #{$mediaMin750} {
              border: 2px solid $colorGray;
              padding: 10px 15px;
              position: relative;
              @include sfColor(color, $colorBlack, $colorGray);

              &:after {
                color: #ccc0a7;
                content: "›";
                display: block;
                font-size: 2em;
                height: 30px;
                line-height: 23px;
                position: absolute;
                right: -32px;
                text-align: center;
                top: 7px;
                width: 30px;
              }
            }
          }

          &:last-child {
            @media #{$mediaMin750} {
              margin-right: 0;

              span:after,
              a:after {
                content: normal;
              }
            }
          }

          &.ui-state-active {
            @media #{$mediaMin750} {
              a {
                background: $colorLightestGray;
                border-bottom: 0;
                margin-bottom: -2px;
                padding-bottom: 22px;
                @include sfColor(color, $colorBlack, $colorBlack);

                &:after {
                  @include keyFrameAnimation(S1SaunaConfiguratorTabArrow, 5s, infinite, alternate, 3s);
                }
              }
            }
          }

          &.inactive {
            span {
              @include sfColor(color, $colorGray);
            }
          }
        }
      }

      .button {
        line-height: (24em/18);
        margin: 30px 0 0 0;

        @media #{$mediaMin1024} {
          margin: 0;
          position: absolute;
          right: 0;
          top: 0;
        }
      }

      > .button a,
      > h3 {
        border: 2px solid $colorGray;
        cursor: pointer;
        display: block;
        margin-bottom: 5px;
        padding: 10px 50px 10px 15px;
        position: relative;
        text-decoration: none;
        text-transform: none;
        @include sfColor(background, $colorWhite, $colorGray);
        @include sfColor(color, $colorBlack, $colorWhite);
        @include sfFutura('Medium');

        &:after {
          content: "›";
          display: block;
          font-size: 2em;
          height: 30px;
          line-height: 23px;
          margin-top: -15px;
          position: absolute;
          right: 8px;
          text-align: center;
          top: 50%;
          width: 30px;
        }

        &.inactive {
          @include sfColor(background, $colorWhite, $colorWhite);
          @include sfColor(color, $colorGray, $colorGray);
          cursor: default;

          &:after {
            content: normal;
          }
        }
      }

      > .buttonDark a {
        @include sfColor(background, $colorLightestGray, $colorGray);

        @media #{$mediaMin750} {
          @include sfColor(background, $colorWhite, $colorGray);
        }
      }

      > h3 {
        &:after {
          @include rotate(90deg);
        }

        @media #{$mediaMin750} {
          display: none;
        }
      }

      .tabElement {
        background: $colorLightestGray;
        margin-bottom: 5px;
        padding: 15px 15px 5px;
        z-index: auto;

        @media #{$mediaMin750} {
          border: 2px solid $colorGray;
          position: relative;
        }

      }

      .selectionButtons,
      .multiSelectionButtons {
        margin: 0;

        li,
        .svg & li {
          background: none;
          margin-bottom: 10px;
          padding: 0;

          @media #{$mediaMin750} {
            display: block;
            float: left;
            margin: 0 10px 10px 0;
          }

          a {
            border: 2px solid $colorGray;
            display: block;
            padding: 10px 15px;
            text-align: center;
            text-decoration: none;
            @include sfColor(background, $colorWhite, $colorGray);
            @include sfColor(color, $colorBlack, $colorWhite);
            @include sfFutura('Medium');
          }

          &.info {
            text-align: right;

            @media #{$mediaMin750} {
              text-align: left;
              margin: 0 10px 20px 5px;
            }

            a {
              padding: 0;
            }

            .openLayer {
              display: inline-block;
              position: relative;
              left: auto;
              right: 0;
              top: 5px;

              @media #{$mediaMin750} {
                position: relative;
                left: 0;
                right: auto;
                top: 50%;
                margin-top: -2px;
              }
            }

            .infoLayer {
              text-align: left;
            }
          }
        }
      }

      .multiSelectionButtons {
        li,
        .svg & li {
          position: relative;

          a {
            padding: 10px 50px 10px 15px;

            &:after {
              content: "";
              display: block;
              height: 30px;
              margin-top: -15px;
              position: absolute;
              right: 8px;
              top: 50%;
              width: 30px;
              @include sfBackgroundImage('svg', 'icons-s1-konfigurator', 600px 300px, $imagePathS1);
              @include sfBackgroundPosition(-50px -200px);
            }

            &:hover,
            &:focus,
            &:active {
              &:after {
                @include sfBackgroundPosition(-150px -250px);
              }
            }

            &.active {
              &:after {
                @include sfBackgroundPosition(-150px -250px);
              }

              &:hover,
              &:focus,
              &:active {
                &:after {
                  @include sfBackgroundPosition(-150px -200px);
                }
              }
            }
          }
        }
      }
    }

    .columns {
      @media #{$mediaMin1024} {
        margin: 0 auto;
        width: (954px/3)*2+60;
      }

      @media #{$mediaMin1280} {
        width: (1120px/3)*2+60;
      }

      .selection {
        margin: 0 auto 45px;
        max-width: 400px;
        position: relative;

        @media #{$mediaMin1024} {
          float: left;
          max-width: none;
          padding: 0 15px;
          width: (100%/2);
        }

        .selectionContent {
          border: 2px solid $colorGray;
          display: block;
          min-height: 210px;
          padding: 15px 15px 30px;
          position: relative;
          text-decoration: none;
          @include sfColor(background, $colorWhite, $colorGray);
          @include sfColor(color, $colorBlack, $colorWhite);

          &:hover,
          &:focus,
          &:active {
            p.more {
              background-color: $colorDarkGrayHover;
              border-color: $colorDarkGrayHover;
              color: $colorWhite;

              &:before {
                color: $colorWhite;
              }
            }
          }
        }

        &.voltage {
          .selectionContent {
            &:after {
              bottom: 15px;
              content: "";
              display: block;
              height: 40px;
              position: absolute;
              right: 15px;
              width: 40px;
              @include sfBackgroundImage('svg', 'icons-s1-konfigurator', 600px 300px, $imagePathS1);
            }
          }
        }

        &.voltage230 {
          .selectionContent {
            &:after {
              @include sfBackgroundPosition(-450px -200px);
            }

            &:hover,
            &:focus,
            &:active {
              &:after {
                @include sfBackgroundPosition(-500px -200px);
              }
            }
          }
        }

        &.voltage400 {
          .selectionContent {
            &:after {
              @include sfBackgroundPosition(-450px -150px);
            }

            &:hover,
            &:focus,
            &:active {
              &:after {
                @include sfBackgroundPosition(-500px -150px);
              }
            }
          }
        }

        p {
          margin-bottom: 0;

          &.more {
            border: 2px solid $colorGray;
            bottom: -22px;
            height: 44px;
            left: 50%;
            margin-left: -22px;
            position: absolute;
            width: 44px;
            @include border-radius(50%);
            @include keyFrameAnimation(S1SaunaConfiguratorButtonCircle, 5s, infinite, alternate, 0s);
            @include sfColor(background, $colorWhite, $colorDarkGrayHover);
            @include sfColor(border, $colorGray, $colorDarkGrayHover);
            @include sfColor(color, $colorBlack, $colorWhite);
            @include sfRemoveTextByIndent();

            &:before {
              color: $colorBlack;
              content: "›";
              display: block;
              font-size: 2em;
              height: 40px;
              left: 0;
              line-height: 34px;
              position: absolute;
              text-align: center;
              top: 0;
              width: 40px;
              @include sfFutura('Medium');
              @include sfShowTextByIndent();
            }
          }
        }

        &.selectionTeaser {
          .selectionContent {
            @include sfColor(background, $colorLightestGray, $colorLightestGray);
            @include sfColor(color, $colorBlack, $colorBlack);
          }

          a {
            float: right;
            @include sfColor(color, $colorBlack, $colorBlack);
          }
        }

        dl {
          float: none;
          margin: 0;

          dt,
          dd {
            display: block;
            float: left;
            padding: 0;
          }

          dt {
            clear: left;
            margin-right: 10px;
          }

          dd {
            clear: right;
          }
        }
      }

      &.columns3 {
        @media #{$mediaMin1024} {
          margin: 0 -15px;
          width: 954px;

          .selection {
            width: (100%/3);
          }
        }

        @media #{$mediaMin1280} {
          width: 1210px;
        }
      }
    }

    .configurationWrapper {
      margin: 0 auto;
      max-width: 700px;

      @media #{$mediaMin1024} {
        margin: 0 -15px;
        max-width: none;
        width: 954px;
      }

      @media #{$mediaMin1280} {
        width: 1210px;
      }

      .column {
        width: 100%;

        @media #{$mediaMin1024} {
          float: left;
          margin: 0;
          padding: 0 15px;
          width: 50%;
        }
      }
    }

    /* Information-Layer */

    .openLayer {
      border: 2px solid $colorGray;
      bottom: -10px;
      cursor: pointer;
      display: block;
      font-size: 1.2em;
      height: 30px;
      position: absolute;
      right: -10px;
      width: 30px;
      z-index: 1;
      @include border-radius(50%);
      @include keyFrameAnimation(S1SaunaConfiguratorButtonCircle, 5s, infinite, alternate, 0s);
      @include sfBackgroundImage('svg', 'icons-s1', 1024px 1024px, $imagePathS1);
      @include sfBackgroundPosition(-787px -657px, -857px -657px);
      @include sfColor('background', $colorWhite, $colorDarkGrayHover);
      @include sfColor('border', $colorLightGray, $colorDarkGrayHover);
      @include sfColor('color', $colorBlack, $colorWhite);
      @include sfFutura('Medium');
      @include sfRemoveTextByIndent();

      @media #{$mediaMin1024} {
        right: 5px;
      }
    }

    .tabWrapper {
      .openLayer {
        right: 10px;
        top: 10px;
      }
    }

    .infoLayer,
    .closeLayer {
      display: none;
    }

    .infoLayerBG {
      background: rgba($colorBlack, 0.75);
      border: 1px solid $colorWhite;
      display: none;
      height: 100%;
      left: 0;
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 31;
    }

    .closeLayer {
      border: 2px solid $colorLightGray;
      background: none;
      display: block;
      height: 30px;
      position: absolute;
      right: 10px;
      top: 10px;
      width: 30px;
      z-index: 33;
      @include border-radius(50%);
      @include sfColor('background', $colorWhite, $colorDarkGrayHover);
      @include sfColor('border', $colorLightGray, $colorDarkGrayHover);
      @include sfColor('color', $colorBlack, $colorWhite);
      @include sfRemoveTextByIndent();

      &:after {
        content: "";
        height: 14px;
        left: 6px;
        position: absolute;
        top: 6px;
        width: 14px;
        @include border-radius(5px);
        @include sfBackgroundImage('svg');
        @include sfBackgroundPosition(-315px -215px);
      }

      &:hover,
      &:active,
      &:focus {
        background-image: none;

        &:after {
          @include sfBackgroundPosition(-215px -215px);
        }
      }
    }

    .infoLayer {
      background: $colorLightestGray;
      height: 250px;
      left: 0;
      margin-top: -125px;
      padding: 30px 30px 0;
      position: fixed;
      top: 50%;
      width: 100%;
      z-index: 32;

      .layerContent {
        height: 200px;
        overflow: hidden;

        @media #{$mediaMin750} {
          height: 200px;
        }
      }

      h3 {
        margin-bottom: 10px;
        padding-right: 30px;
      }

      @media #{$mediaMin750} {
        height: 250px;
        left: 50%;
        margin: -175px 0 0 -250px;
        width: 500px;
      }
    }

    .scroll-content-vertical {
      padding-right: 10px;
    }

    .scroll-bar-wrap-vertical {
      display: none;
      border-left: 2px solid $colorBlack;
      width: 10px;

      .ui-slider {
        background: transparent;
        width: 0px;
      }

      .ui-handle-helper-parent {
        width: 1px;
      }

      .ui-slider-handle {
        background-color: $colorGray;
        cursor: pointer;
        margin-left: -6px;
        margin-bottom: 0;
        width: 10px;
        @include border-radius(5px);
        @include sfColor(background, $colorGray, $colorBlack);
      }
    }
  }

  .contentS1ShowConfiguration,
  .contentS1SaunaConfigurator {

    h1 {
      @media #{$mediaMin750} {
        margin-bottom: 20px;
      }
    }

    .thumbnails {
      display: none;
      padding-top: 45px;

      @media #{$mediaMin750} {
        display: block;
        margin: 0 -78px;

        .item {
          display: block;
          float: left;
          padding: 0;
          width: (100%/5);
        }

        a {
          display: block;
          border: 2px solid $colorLineGray;
          margin: 15px;

          img {
            opacity: 0.75;
            @include single-transition(opacity, 1s);
          }

          &:hover,
          &:active,
          &:focus {
            img {
              opacity: 1;
            }
          }
        }
      }

      @media #{$mediaMin1024} {
        margin: 0 -15px;
      }
    }

    .thumbnailSlider {
      .rsSlide {
        background: $colorWhite;
      }

      .rsThumbsContainer {
        margin: 0 auto;
        width: 280px !important;
        @include transform(none !important);
        @include transition-property(none !important);

        @media #{$mediaMin750} {
          width: 380px !important;
        }
      }

      .rsThumbs {
        margin: 0;
        width: 100%;
      }

      .rsThumb {
        border: 2px solid $colorGray;
        display: inline-block;
        float: none;
        margin: 1px 5px;
        padding: 5px;
        width: 60px;

        @media #{$mediaMin750} {
          margin: 6px 10px;
          width: 75px;
        }

        img {
          opacity: 0.5;
          @include single-transition(opacity, 2s);
        }

        &.rsNavSelected {
          img {
            opacity: 1;
          }
        }
      }

      .rsArrow {
        position: absolute;
        width: 50px;
        height: 100px;
        top: 50%;
        margin-top: -50px;
        z-index: 11;

        &:before {
          display: block;
          line-height: 65px;
          font-size: 8em;
          color: $colorGray;
        }

        &:hover,
        &:focus,
        &:active {
          &:before {
            color: $colorDarkGrayHover;
          }
        }
      }

      .rsArrowLeft {
        left: 0;

        &:before {
          content: "‹";
        }
      }

      .rsArrowRight {
        right: 0;

        &:before {
          content: "›";
          text-align: right;
        }
      }

      .showConfiguration {
        display: block !important;
        margin-bottom: 15px !important;

        &:after {
          bottom: 7%;
        }
      }
    }
  }

  /* Konfiguration ansehen */
  .contentS1ShowConfiguration {
    padding-top: 40px;

    * {
      &:focus,
      &:active {
        outline: none;
      }
    }

    #pageInLayer & {
      padding-top: 0;
    }

    .configurationWrapper {
      .previewImage {
        .showConfiguration {
          display: none;

          @media #{$mediaMin750} {
            display: block;
            margin-bottom: 60px;
          }
        }

        .switch {
          bottom: 0;
          top: auto;
          left: 50%;
          margin-left: -38px;
          z-index: 1;

          @media #{$mediaMin750} {
            bottom: auto;
            top: 340px;
          }

          @media #{$mediaMin1024} {
            top: 330px;
          }

          @media #{$mediaMin1280} {
            top: 375px;
          }
        }
      }

      .thumbnails {
        display: block;
        padding: 0;
        margin-bottom: 60px;

        @media #{$mediaMin1024} {
          margin: 0 -15px 60px;
        }

        li,
        .svg & li {
          background: none;
          padding: 0;
        }

        .rsBullets {
          position: absolute;
          left: 0;
          top: 20px;
          width: 100%;
          text-align: center;
          padding-bottom: 5px;

          .rsBullet {
            display: inline-block;
            line-height: 0;
            width: 12px;
            height: 12px;
            margin: 0 10px;
            @include border-radius(50%);
            border: 2px solid $colorGray;
            @include sfColor('background', transparent, $colorGray);

            &.rsNavSelected {
              @include sfColor('background', $colorGray);
            }
          }
        }

        .rsArrow {
          position: absolute;
          width: 50px;
          height: 100px;
          top: 50%;
          margin-top: -50px;

          &:before {
            display: block;
            line-height: 65px;
            font-size: 8em;
            color: $colorGray;
          }

          &:hover,
          &:focus,
          &:active {
            &:before {
              color: $colorDarkGrayHover;
            }
          }
        }

        .rsArrowLeft {
          left: 0;

          &:before {
            content: "‹";
          }
        }

        .rsArrowRight {
          right: 0;

          &:before {
            content: "›";
            text-align: right;
          }
        }

        .rsArrowDisabled {
          display: none !important;
        }
      }

      .buttonWrapper {
        width: 100%;
      }
    }
  }
}

@keyframes S1SaunaConfiguratorSteps {
  50% {
    background-color: $colorLightestGray;
    border-color: $colorLightestGray;
    @include scale(1.1);
  }
}

@keyframes S1SaunaConfiguratorButton {
  50% {
    background-color: $colorGray;
    border-color: $colorGray;
  }
}

@keyframes S1SaunaConfiguratorButtonCircle {
  50% {
    @include scale(1.2);
  }
}

@keyframes S1SaunaConfiguratorTabArrow {
  50% {
    @include scale(1.2);
  }
}